.c-site-footer{
  width: 100%;
  padding-top: 28px;
  background-color: $navy;
  //height: 345px;
  // border-top:  solid 1.1px $grey-3;
  @media #{$desktop}{
    padding: 15px 50px;
    border-top: none;
  }
  @media #{$tablet}{
    padding: 30px;
    height: auto;
    margin-top: 0;
  }
  
  @media #{$mobile} {
    padding: 32px 0;
    height: auto;

  }
  &__copyright {
    //margin-top: 61px;
    font-size: rem(12);
    color: $white;
    text-align: right;
    width: max-content;
    float: right;
    font-weight: $regular;

    @media #{$tablet}{
      font-size: rem(11);
      letter-spacing: 0;
    }

    @media #{$mobile} {
      margin-top: 5px;
      font-size: rem(11);
      font-weight: $light;
      //text-align: center;
      margin-bottom: 25px;
      float: left;
    }
    >p{
      font-family: $sans-1;
      font-size: rem(13);
      letter-spacing: 0.44px;
      color: $white;
      font-weight: $regular;
      @media #{$mobile} {
        font-size: rem(11);
        font-weight: $regular;
      }
    }
  }
  
}

