

a>p.a-section{
  padding: 3px 15px 1px;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  font-family: $sans-2;
  background-color: $black;
  font-size: rem(18);
  font-weight: $sbold;
  letter-spacing: 0.27px;
  color: $white;
  text-transform: uppercase;
  z-index: 1;
}
a.card {

  display: block;
  
  &:hover {
    text-decoration: none;
  }


  article {
    height: 100%;
  

    > figure {
      position: relative;
      
      
      &.video {
        &:before{
          @include videoIcon();
        }
      }


       // IE10+ HACK
       _: -ms-lang(x) {
        overflow: hidden !important;

       }
      // IE9 HACK
       :root img {
         height: auto;
         width: auto;
         max-width: none;
       }  

       // IE10+ HACK
       _: -ms-lang(x), img {
         height: auto !important;
         width: auto !important;
         min-width: 100% !important;
         max-width: 100% !important;
         min-height: 100% !important;
         max-height: 100% !important;
       }


      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }


    }





    > .a-content {
      width: 100%;
      // @include flexbox;
      // @include flex-direction(column);
      // justify-content: flex-start;

      >p {
        margin: 0;
        padding: 0;
        overflow: hidden;
        &.a-excerpt{
          font-size: rem(13);
          line-height: 1.31;
          color: $grey-1;
        }
      }


      .a-subject{
        font-size: rem(11);
        color: $navy;
        text-transform: uppercase;
        font-weight: $medium;
        height: max-content;
        position: relative;
        display: block;
        &.a-subject--factcheck{
          color: $orange;
        }
      }

      >a.verdict{
          display: none;    
      }

      h2{
        font-family: $base-font;
        font-size: rem(48);
        font-weight: 300;
        margin: 0;
        overflow: hidden;
        padding-bottom: 1px;
        position: relative;
        display: block;
      }


      > time{
        display: none;
      }
      .a-arrow{
        display: none;
      }
    }
  }
  &.card-noimage{
    background-color: $grey-4;
  }

}
:first-child{
  >a>p.a-section{
    display: block;
  }
}