/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http: //csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1 {
  font-size: 2.25rem;
  font-family:$base-font;
  color: color(font-header);
  font-weight: 300;
}

h2 {
  font-size: 2.25rem;
  color: color(font-header);
  font-family:$base-font;
  font-weight: 300;
}

h3 {
  font-size: 1.5rem;
  font-family:$base-font;
  color: color(font-alternate);
}

h4 {
  font-size: 1.5rem;
  color: color(font-alternate);
  font-family:$base-font;
}

h5 {
  font-size: 1.2rem;
  font-family:$base-font;
  color: color(font-text);
}

h6 {
  font-size: 1.2rem;
  color: color(font-text);
  font-family:$base-font;
}
