.owl-carousel .owl-item img{
  //max-height: 642px;
  height: 100%;
  width: 100%;
  // @media #{$tablet} {
  //   max-height: 448px;
    
  // }
}
.owl-stage-outer{
  height: 100%;
}
.owl-next{
  float: right;
}

.owl-prev, .owl-next{
  >div>svg>g{
    stroke: $white;
  }
  &.disabled{
    >div>svg>g{
      stroke: $grey-1;
    }
  }
}
.owl-item{
  height: 100%;
  padding-bottom: 50px;
  @media #{$mobile}{
    padding-bottom: 30px;
  }
}
.owl-theme .owl-controls .owl-page {
  display: inline-block;
}
.owl-pagination{
  display: none;
}

.owl-nav{
  width: 100%;
  position: absolute;
  top: 250px;
  @media #{$desktop-small}{
    top: 150px;
  }
  @media #{$mobile}{
    top: 95px;
  }
  @media #{$tablet}{
    top: 120px;
  }
}

.owl-theme .owl-controls .owl-page span {
  display: block;
  width: 19px;
  padding: 0;
  border-bottom: solid 1px #ffffff;
  opacity: 0.99;
  float: right;
  margin: 0;
  cursor: pointer;
  &:before {
    content: '•';
    font-size: rem(40);
    font-weight: $bold;
    //font-stretch: condensed;
    color: $white;
    opacity: 0.99;
    text-shadow: -1.5px -1.5px 0 #000, 1.5px -1.5px 0 #000, -1.5px 1.5px 0 #000, 1.5px 1.5px 0 #000;
    -webkit-text-stroke: 1.5px white;
  
  }
}

.owl-theme .owl-controls .active span{
  opacity: 0.99;
  &:before {
    color: $orange;
  }
}

