.c-team {

  &__image{
    width: 100%;

  }
  &__person{
    font-size: rem(26);
    font-weight: $xlight;
    line-height: 1.08;
    letter-spacing: normal;
    color: $grey-1;
    margin-bottom: 10px;
  }
  &__head{
    font-size: rem(26);
    font-weight: $bold;
    line-height: 1.08;
    letter-spacing: normal;
    color: $black;
    margin-bottom: 10px;
  }
  &__position{
    font-size: rem(14);
    font-weight: $sbold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color:$black;
  
  }
  &__description{
    >p{
      font-size: rem(14);
      line-height: 1.75;
      color:  $grey-1;
      
    }
  }

}
