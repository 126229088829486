.e-advertisment {
  position: relative;
  margin: 0 auto 30px;
  >a>figure{
    display: block;
    margin: 0;
  }
  &--mrec{
    display: none;
    @media #{$desktop}{
      display: block;
      width: 300px;
      height: 250px;
      margin-top: 40px;
    }
  }
  &--billboard{
    display: none;
    @media #{$desktop}{
      display: block;
      width: 970px;
      height: 250px;
      margin-top: 60px;
      margin-bottom: 0;
    }
  }
  &--leaderboard{
    display: none;
    @media #{$desktop}{
      display: block;
      width: 970px;
      height: 90px;
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
  &--header{
    display: none;
    @media #{$desktop}{
      display: block;
      width: 970px;
      height: 90px;
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
  &--tablet,&--mobile{
    margin: 0 auto;
    >a>figure>img{
      margin: 0 auto;
      display: block;
      
    }
  }
}