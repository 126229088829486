.c-contact {
  &__head{
    font-size: rem(40);
    font-weight: $bold;
    line-height: 1.05;
    color: $orange;


    @media #{$tablet-up}{
      writing-mode: vertical-rl;
      text-orientation: mixed;
    }
  }

  &__field{
    height: 50px;
    width: 100%;
    border: $grey-1 1px solid;
    border-radius: 9px;
    padding: 21px 15px;
    margin: 0 0 15px 0;
    font-family: $sans-2;
    font-size: rem(16);
    font-weight: $sbold;
    line-height: 1.13;
    &::placeholder{
      color: $grey-2;
      font-weight: $medium;
    }
    
  }

  &-dropdown{

    &__selected{
      height: 50px;
      width: 100%;
      border: $grey-1 1px solid;
      border-radius: 9px;
      padding: 15px 21px;
      margin: 15px 0;
      
      position: relative;
      cursor: pointer;
    }
    &__selecttext{
      font-family: $sans-2;
      font-size: rem(16);
      font-weight: $sbold;
      line-height: 1.13;
    }
    &__arrow{
      width: 25px;
      height: 25px;
      position: absolute;
      right: 21px;
      top: 15px;
    
      &--active{
        transform: rotate(180deg);
        right: 28px;
        top: 18px;
      }
    }

    &__list{
      width: calc(100% - 60px);
      position: absolute;
      z-index: 1;
      margin-top: -15px;
      &item{
        list-style: none;
        height: 50px;
        width: 100%;
        border: $grey-1 1px solid;
        padding: 15px 21px;
        margin: 0;
        font-family: $sans-2;
        font-size: rem(16);
        font-weight: $sbold;
        line-height: 1.13;
        color: $grey-1;
        background-color: $white;
        cursor: pointer;
        &--selected{
          background-color: $grey-3;
          color: $orange;
        }
      }
    }

  }



  &__submit{
    background-color: $orange;
    color: $white;
    padding: 9px 35px;
    font-size: rem(16);
    border: none;
    font-weight: $bold;
    cursor: pointer;
    &:hover{
      padding: 8px 34px;
      border: 1px solid $orange;
      background-color: $white;
      color: $orange;
    }
    &--disabled{
      background-color: $grey-3;
      cursor: unset;
      &:hover{
        border: none;
        background-color: $grey-3;
        color: $white;
      }

    }
  }
  &__textarea{
    width: 100%;
    border: $grey-1 1px solid;
    padding: 10px;
    margin: 15px 0;
    max-width: 100%;
    min-width: 100%;
    font-family: $sans-2;
    font-size: rem(16);
    font-weight: $sbold;
    line-height: 1.13;
    &::placeholder{
      color: $grey-2;
      font-weight: $medium;
    }
  }

}
