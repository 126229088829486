.c-login-form{
 
  &__title{
    font-size: rem(18);
    font-weight: $sbold;
    line-height: normal;
    letter-spacing: 0.62px;
    color: $orange;
    margin-bottom: 20px;
  }
  &__input{
    width: 100%;
    height: 48px;
    border: solid 1px $grey-3;
    font-size: rem(16);
    font-weight: $light;
    line-height: 1.06;
    letter-spacing: 0.55px;
    color: $grey-1;
  }
  &__button{
    text-transform: uppercase;
    font-size: rem(12);
    font-weight: $sbold;
    line-height: 1.75;
    text-align: center;
    color: $white;

  }
}