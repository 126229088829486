
/* reset from content builder */
.row > * {
  min-height: 1;
}

div[class*='col-sm'] {
  @media screen and (min-width: 500px) and (max-width: $sm-break-down) {
    &.split {
      width: 50%;
      float: left;
    }
      &.clearfixed {
      clear: both;
    }
  }
}

div[class*='col-sm'] {
  &.large-split {
    width: 50%;
    float: left;
  }
  &.clearfixed {
    clear: both;
  }
}

.no-padding{
  @media #{$tablet-up} {
    padding: 0;
  }
}
.align-left{text-align: left;}
.align-center{text-align: center;}
.relative { position: relative; }
.u-no-margin-bottom{ margin-bottom: 0;}
.u-no-margin-top{ margin-top: 0;}
.no-padding {padding: 0;}
.u-padding-5 { padding: 5px; }
.u-padding-10 { padding: 10px; }
.u-margin-top-100 { margin-top: 100px; }
.u-margin-top-90 { margin-top: 90px; }
.u-margin-top-80 { margin-top: 80px; }
.u-margin-top-70 { margin-top: 70px; }
.u-margin-top-60 { margin-top: 60px; }
.u-margin-top-50 { margin-top: 50px; }
.u-margin-top-40 { margin-top: 40px; }
.u-margin-top-30 { margin-top: 30px; }
.u-margin-top-25 { margin-top: 25px; }
.u-margin-top-20 { margin-top: 20px; }
.u-margin-top-15 { margin-top: 15px; }
.u-margin-top-10 { margin-top: 10px; }

.u-margin-bottom-135 { margin-bottom: 135px; }
.u-margin-bottom-100 { margin-bottom: 100px; }
.u-margin-bottom-80 { margin-bottom: 80px; }
.u-margin-bottom-60 { margin-bottom: 60px; }
.u-margin-bottom-50 { margin-bottom: 50px; }
.u-margin-bottom-40 { margin-bottom: 40px; }
.u-margin-bottom-30 { margin-bottom: 30px; }
.u-margin-bottom-25 { margin-bottom: 25px; }
.u-margin-bottom-20 { margin-bottom: 20px; }
.u-margin-bottom-15 { margin-bottom: 15px; }
.u-margin-bottom-10 { margin-bottom: 10px; }

@media #{$mobile} {
  .u-mobile-float-left { float: left; }
  .u-mobile-float-right { float: right; }
  .u-mobile-clear-both { clear: both; }

  .u-mobile-no-margin-top {margin-top: 0!important;}
  .u-mobile-no-margin-bottom {margin-bottom: 0!important;}
  .u-mobile-margin-bottom-80 { margin-bottom: 80px; }
  .u-mobile-margin-bottom-60 { margin-bottom: 60px; }
  .u-mobile-margin-bottom-50 { margin-bottom: 50px; }
  .u-mobile-margin-bottom-40 { margin-bottom: 40px; }
  .u-mobile-margin-bottom-30 { margin-bottom: 30px; }
  .u-mobile-margin-bottom-25 { margin-bottom: 25px; }
  .u-mobile-margin-bottom-20 { margin-bottom: 20px; }
  .u-mobile-margin-bottom-15 { margin-bottom: 15px; }
  .u-mobile-margin-bottom-10 { margin-bottom: 10px; }

  .u-mobile-margin-top-110 { margin-top: 110px; }
  .u-mobile-margin-top-85 { margin-top: 85px; }
  .u-mobile-margin-top-60 { margin-top: 60px; }
  .u-mobile-margin-top-50 { margin-top: 50px; }
  .u-mobile-margin-top-40 { margin-top: 40px; }
  .u-mobile-margin-top-30 { margin-top: 30px; }
  .u-mobile-margin-top-25 { margin-top: 25px; }
  .u-mobile-margin-top-20 { margin-top: 20px; }
  .u-mobile-margin-top-15 { margin-top: 15px; }
  .u-mobile-margin-top-10 { margin-top: 10px; }

}
@media #{$tablet} {
  .u-tablet-float-left { float: left; }
  .u-tablet-float-right { float: right; }
  .u-tablet-clear-both { clear: both; }

  .u-tablet-no-margin-top {margin-top: 0!important;}
  .u-tablet-no-margin-bottom {margin-bottom: 0!important;}
  .u-tablet-margin-bottom-60 { margin-bottom: 60px; }
  .u-tablet-margin-bottom-50 { margin-bottom: 50px; }
  .u-tablet-margin-bottom-40 { margin-bottom: 40px; }
  .u-tablet-margin-bottom-30 { margin-bottom: 30px; }
  .u-tablet-margin-bottom-25 { margin-bottom: 25px; }
  .u-tablet-margin-bottom-20 { margin-bottom: 20px; }
  .u-tablet-margin-bottom-15 { margin-bottom: 15px; }
  .u-tablet-margin-bottom-10 { margin-bottom: 10px; }

  .u-tablet-margin-top-100 { margin-top: 100px; }
  .u-tablet-margin-top-60 { margin-top: 60px; }
  .u-tablet-margin-top-50 { margin-top: 50px; }
  .u-tablet-margin-top-40 { margin-top: 40px; }
  .u-tablet-margin-top-30 { margin-top: 30px; }
  .u-tablet-margin-top-25 { margin-top: 25px; }
  .u-tablet-margin-top-20 { margin-top: 20px; }
  .u-tablet-margin-top-15 { margin-top: 15px; }
  .u-tablet-margin-top-10 { margin-top: 10px; }


}
@media #{$desktop} {
  .u-desktop-float-left { float: left; }
  .u-desktop-float-right { float: right; }
  .u-desktop-clear-both { clear: both; }

  .u-desktop-no-margin-top {margin-top: 0!important;}
  .u-desktop-no-margin-bottom {margin-bottom: 0!important;}
  .u-desktop-margin-bottom-120 { margin-bottom: 120px; }
  .u-desktop-margin-bottom-100 { margin-bottom: 100px; }
  .u-desktop-margin-bottom-90 { margin-bottom: 90px; }
  .u-desktop-margin-bottom-80 { margin-bottom: 80px; }
  .u-desktop-margin-bottom-70 { margin-bottom: 70px; }
  .u-desktop-margin-bottom-60 { margin-bottom: 60px; }
  .u-desktop-margin-bottom-50 { margin-bottom: 50px; }
  .u-desktop-margin-bottom-40 { margin-bottom: 40px; }
  .u-desktop-margin-bottom-30 { margin-bottom: 30px; }
  .u-desktop-margin-bottom-25 { margin-bottom: 25px; }
  .u-desktop-margin-bottom-20 { margin-bottom: 20px; }
  .u-desktop-margin-bottom-15 { margin-bottom: 15px; }
  .u-desktop-margin-bottom-10 { margin-bottom: 10px; }

  .u-desktop-margin-top-100 { margin-top: 100px; }
  .u-desktop-margin-top-90 { margin-top: 90px; }
  .u-desktop-margin-top-80 { margin-top: 80px; }
  .u-desktop-margin-top-60 { margin-top: 60px; }
  .u-desktop-margin-top-50 { margin-top: 50px; }
  .u-desktop-margin-top-45 { margin-top: 45px; }
  .u-desktop-margin-top-40 { margin-top: 40px; }
  .u-desktop-margin-top-30 { margin-top: 30px; }
  .u-desktop-margin-top-25 { margin-top: 25px; }
  .u-desktop-margin-top-20 { margin-top: 20px; }
  .u-desktop-margin-top-15 { margin-top: 15px; }
  .u-desktop-margin-top-10 { margin-top: 10px; }
}

.u-border{
  &-top{
    border-top: 1px solid $grey-1;
  }
  &-bottom{
    border-bottom: 1px solid $grey-1;
  }
  &-left{
    border-left: 1px solid $grey-1;
  }
  &-right{
    border-right: 1px solid $grey-1;
  }
}

.u-mobile-border{
  @media #{$mobile} {
    &-top{
      border-top: 1px solid $grey-1;
    }
    &-bottom{
      border-bottom: 1px solid $grey-1;
    }
    &-left{
      border-left: 1px solid $grey-1;
    }
    &-right{
      border-right: 1px solid $grey-1;
    }
  }
}

.u-tablet-border{
  @media #{$tablet} {
    &-top{
      border-top: 1px solid $grey-1;
    }
    &-bottom{
      border-bottom: 1px solid $grey-1;
    }
    &-left{
      border-left: 1px solid $grey-1;
    }
    &-right{
      border-right: 1px solid $grey-1;
    }
  }
}

.u-desktop-border{
  @media #{$desktop} {
    &-top{
      border-top: 1px solid $grey-1;
    }
    &-bottom{
      border-bottom: 1px solid $grey-1;
    }
    &-left{
      border-left: 1px solid $grey-1;
    }
    &-right{
      border-right: 1px solid $grey-1;
    }
  }
}

.u-border-white{
  border-color: $white;
}

@media #{$mobile} {
  .five-cols .col-md-1,
  .five-cols .col-sm-1,
  .five-cols .col-lg-1  {
  width: 100%;
  *width: 100%;
  }
}
@media #{$tablet} {
  .five-cols .col-md-1,
  .five-cols .col-sm-1,
  .five-cols .col-lg-1 {
    width: 20%;
    *width: 20%;
  }
}

@media #{$tablet} {
  .two-cols {
    .col-md-1, .col-sm-1, .col-lg-1 {
      width: 50%;
    }
  }
}

@media #{$desktop} {
  .two-cols .col-md-2,
  .two-cols .col-sm-2,
  .two-cols .col-lg-2  {
    width: 100%;
    *width: 100%;
  }
}

@media #{$tablet} {
  .sm-two-cols {
    .col-md-1, .col-sm-1, .col-lg-1 {
      width: 50%;
    }
  }
}

@media  #{$tablet-up} {
  .three-cols .col-md-1,
  .three-cols .col-sm-1,
  .three-cols .col-lg-1 {
    width: 33.3333%;
    *width: 33.3333%;
  }
}

@media  #{$desktop} {
  .three-cols .col-md-2,
  .three-cols .col-sm-2,
  .three-cols .col-lg-2  {
    width: 100%;
    *width: 100%;
  }
}

@media  #{$tablet-up} {
  .col-profile-left, .col-profile-right {
    float: left;
  }
  .col-profile-left {
    width: 25%;
    padding-right: 30px;
  }
  .col-profile-right {
    width: 75%;
    padding-left: 30px;
  }
}

// 20px gutter
.gutter-60.row {
  margin-right: -30px;
  margin-left: -30px;
}
.gutter-60 > [class^='col-'], .gutter-60 > [class^=' col-'] {
  padding-right: 30px;
  padding-left: 30px;
}

// 20px gutter
.gutter-20.row {
  margin-right: -10px;
  margin-left: -10px;
}
.gutter-20 > [class^='col-'], .gutter-20 > [class^=' col-'] {
  padding-right: 10px;
  padding-left: 10px;
}
// 10px gutter
.gutter-10.row {
  margin-right: -5px;
  margin-left: -5px;
}
.gutter-10 > [class^='col-'], .gutter-10 > [class^=' col-'] {
  padding-right: 5px;
  padding-left: 5px;
}
// 0px gutter
.gutter-0.row {
  margin-right: 0;
  margin-left: 0;
}
.gutter-0 > [class^='col-'], .gutter-0 > [class^=' col-']{
  padding-right: 0;
  padding-left: 0;
}

@-ms-viewport {
  width: device-width; }

.visible-xs {
  display: none !important; }

.visible-sm {
  display: none !important; }

.visible-md {
  display: none !important; }

.visible-lg {
  display: none !important; }

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table !important; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; } }

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important; } }

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table !important; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table !important; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table !important; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

.visible-print {
  display: none !important; }

@media print {
  .visible-print {
    display: block !important; }
  table.visible-print {
    display: table !important; }
  tr.visible-print {
    display: table-row !important; }
  th.visible-print,
  td.visible-print {
    display: table-cell !important; } }

.visible-print-block {
  display: none !important; }
  @media print {
    .visible-print-block {
      display: block !important; } }

.visible-print-inline {
  display: none !important; }
  @media print {
    .visible-print-inline {
      display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }
  @media print {
    .visible-print-inline-block {
      display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

.u-noscroll{
  overflow-x: hidden;
  overflow-y: hidden;
}