.c-hero{
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/images/hero.png');
  background-color: $navy;
  padding: 150px 0 250px;
  @media #{$mobile}{
    padding: 100px 0 100px;
  }
  &__icon{
    width: 111px;
    height: 52px;
    padding: 65px 0;
  }

  &__title{
    width: 100%;
    font-size: rem(30);
    font-weight: $bold; 
    line-height: 1.25;
    color: $white;
    // text-align: center;
    margin-bottom: 0;
    @media #{$tablet}{
      font-size: rem(40);
      margin-top: -10px;
      
    }
    @media #{$desktop}{
      font-size: rem(52);
      margin-top: -15px;
      
    }
  }
  

  &__downarrow{
    width: 100%;
    
    >svg{
      // height: 50px;
      display: block;
      margin: 0 auto;
    }
  }

  &__buttoncont{
    position: relative;
  }

  &__button{
    display: block;
    margin: 0;
    height: 40px;
    padding: 11px 29px;
    width: max-content;
    &:hover{
      padding: 10px 28px;
    }
    font-size: rem(16);
    // padding: 0;
    line-height: 1;
  }
}