.modal-header .close {
	position: absolute;
    background: #FFF;
    right: -5px;
    top: 0;
    border: 0;
    background: #FFF;
}
.modal-dialog{
	margin: 175px auto;
}
.modal-header{
	text-align: center;
	border-bottom: 0;
	position: relative;
}
.modal-content{
	border-radius: 0;
	padding: 20px;
}
.hidden-form {
    display: none;
}
.pop-up-close{
	font-size: 22px;
	color: #000;
}
.pop-up-close-svg{
	>svg{
    width: 30px;
    height: 30px;
  }    
    display: block;
    // background-repeat: no-repeat;
    // background-size: contain;
}