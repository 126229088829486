.c-index-panel{
  width: 100%;
  padding: 60px 0 0;
  

  &__head{
    font-size: rem(40);
    font-weight: $bold;
    line-height: 1.05;
    color: $orange;
    @media #{$tablet-up}{
      writing-mode: vertical-rl;
      text-orientation: mixed;
      height: min-content;
      min-height: 180px;
     }
  }

  

  &__excerpt{
    font-size: rem(28);
    font-weight: $bold;
    line-height: 1.43;
    color: $grey-3;
    @media #{$tablet}{
      font-size: rem(30);
      line-height: 1.33;
    }
    @media #{$desktop}{
      font-size: rem(40);
      line-height: 1.25;
    }
  }

  &--whitebg{
    background: $white;
  }

  &--greybg{
    background-color: $grey-4;
    .c-index-panel__excerpt{
      color: $grey-1;
    }
  }

  &__text{
    >p{
      font-family: $sans-2;
      font-size: rem(15);
      line-height: 1.75;
      color: $grey-1;
      margin-bottom: 10px; 
      @media #{$desktop}{
        font-size: rem(20);
        line-height: 1.5;
        margin-bottom: 20px;      
      }
    }
  }

  &__downarrow{
    margin-left: 15px;
    // >svg{
    //   height: 50px;
    // }
  }

  &__rightarrow{
    float: left;
    transform: rotate(270deg);
    margin-left: 30px;
    @media #{$tablet}{
      margin-left: 200px;
      margin-top: 5px;
    }

  }


  &__button{
    float:  left;
    margin-left: 45px;
    margin-top: 10px;
    padding: 10px 20px;
    &:hover{
      padding: 11px 21px;
    }
    @media #{$tablet}{
      width: unset;
      height: unset;
    
    }
    @media #{$desktop}{
      margin-left: 16%;
      width: unset;
      height: unset;
    }
  }
  &__carousel{
    .owl-item{
      padding-bottom: 0 !important;
    }
    .owl-dots{
      position: absolute;
        
      @media #{$mobile}{
        width: max-content;
        right: 15px;
        bottom: -20px;
      }
      @media #{$tablet-up}{
        right: -12.5%;
        bottom: -75px;
        height: max-content;
        width: 6px;
      }
      @media #{$desktop}{
        right: -8.5%;
        bottom: 25px;
        
      }
    }
    
    .owl-dot{
      width: 26px;
      height: 4px;
      background-color: $grey-3 !important;
      margin-right: 3px;
      @media #{$tablet-up}{
        margin-right: 0;
        width: 4px;
        height: 26px;
       
      }
      @media #{$desktop}{
        
        margin-bottom: -3px;
      }
      &.active{
        background-color: $orange !important;
      }
    }
    
  }
  &__singleimage{
    width: 100%;
  }
  &__figure{
    width: 100%;
    margin-bottom: 0;
  }
  &__image{
    width: 100%;
  }
  &__imagetitle{
    font-size: rem(10);
    line-height: 1.6;

    @media #{$mobile}{
      border-bottom: 1px solid $grey-3;
    }
    
    @media #{$tablet-up}{
      writing-mode: vertical-rl;
      text-orientation: mixed;
      height: 383px;
      border-left: 1px solid $grey-3;
      font-size: rem(14);
    }

    @media #{$desktop}{
      height: 443px;
    }
  }
}