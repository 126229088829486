@charset "UTF-8";
/*
  Project: base-theme
  Author: Puck Willis
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ($baseFontSize * 1) + 0px; */
/* desktop min */
/* tablet max */
/* tablet min */
/* mobile max */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https: //www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *  on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
p {
  margin: 0;
  font-size: 16px;
  font-family: "Poppins", helvetica, arial, sans-serif;
}

@media screen and (max-width: 767px) {
  p {
    font-size: 12px;
  }
}

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1250px) {
  .container {
    max-width: 1142px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;
  margin-left: -30px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 30px;
  padding-left: 30px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1250px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -ms-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #ffd7aa;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 156, 42, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ff9c2a;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(255, 156, 42, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #ff9c2a;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #ff9c2a;
  box-shadow: 0 0 0 0.2rem rgba(255, 156, 42, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ff9c2a;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ff9c2a;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #ffd7aa;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #ffb45d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(255, 156, 42, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ff9c2a;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(255, 156, 42, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  -webkit-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  -ms-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-content {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
    -ms-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1250px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1250px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.e-advertisment {
  position: relative;
  margin: 0 auto 30px;
}

.e-advertisment > a > figure {
  display: block;
  margin: 0;
}

.e-advertisment--mrec {
  display: none;
}

@media screen and (min-width: 992px) {
  .e-advertisment--mrec {
    display: block;
    width: 300px;
    height: 250px;
    margin-top: 40px;
  }
}

.e-advertisment--billboard {
  display: none;
}

@media screen and (min-width: 992px) {
  .e-advertisment--billboard {
    display: block;
    width: 970px;
    height: 250px;
    margin-top: 60px;
    margin-bottom: 0;
  }
}

.e-advertisment--leaderboard {
  display: none;
}

@media screen and (min-width: 992px) {
  .e-advertisment--leaderboard {
    display: block;
    width: 970px;
    height: 90px;
    margin-top: 15px;
    margin-bottom: 0;
  }
}

.e-advertisment--header {
  display: none;
}

@media screen and (min-width: 992px) {
  .e-advertisment--header {
    display: block;
    width: 970px;
    height: 90px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.e-advertisment--tablet, .e-advertisment--mobile {
  margin: 0 auto;
}

.e-advertisment--tablet > a > figure > img, .e-advertisment--mobile > a > figure > img {
  margin: 0 auto;
  display: block;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”' '‘' '’';
  font-style: italic;
}

blockquote p:first-child:before {
  content: open-quote;
}

blockquote p:last-child:after {
  content: close-quote;
}

/* ==========================================================================
   #Content of editable pages and posts
   ========================================================================== */
.e-content {
  display: block;
}

.e-content p {
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .e-content p {
    font-size: 16px;
  }
}

.e-content p strong {
  font-weight: 700;
}

.e-content p.has-medium-font-size {
  font-size: 20px;
  color: #000000;
}

.e-content h1, .e-content h2, .e-content h3, .e-content h4 {
  color: #000000;
}

.e-content ul, .e-content ol {
  margin-left: 15px;
}

.e-content ul li, .e-content ol li {
  color: #6D7278;
  font-size: 16px;
  opacity: 0.8;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 15px;
}

.e-content ul li {
  list-style-type: disc;
}

.e-content .wp-block-button {
  margin-right: 10px;
  margin-bottom: 30px;
}

.e-content .wp-block-button a {
  height: 47px;
  padding: 14px 32px;
  background-color: #ff9c2a;
  color: #ffffff;
  font-weight: 700;
  border-radius: unset;
  display: unset;
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .e-content .wp-block-button a {
    width: 100%;
    display: block;
    margin-bottom: 15px;
  }
}

.e-content .wp-block-button a:hover {
  color: #ff9c2a;
  background-color: #ffffff;
  border: 1px solid #ff9c2a;
  padding: 13px 31px;
}

@media screen and (max-width: 767px) {
  .e-content .wp-block-button {
    width: 100%;
  }
}

.e-content .c-button-block {
  width: 100%;
  display: block;
}

.e-content .c-button-block .wp-block-button {
  float: left;
  width: min-content;
}

.e-content a {
  color: #ff9c2a;
}

.e-content .c-embed > iframe {
  max-width: 100%;
}

.e-content .wp-block-image > img {
  max-width: 50%;
  margin-bottom: 2em;
}

@media screen and (max-width: 767px) {
  .e-content .wp-block-image > img {
    max-width: 100%;
    max-height: 250px;
    object-fit: contain;
  }
}

.e-content .wp-block-image > figcaption {
  float: right;
  width: 45%;
  margin-top: 25px;
}

@media screen and (max-width: 767px) {
  .e-content .wp-block-image > figcaption {
    width: 100%;
    margin-top: 0;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.58;
    color: #6D7278;
  }
}

html {
  font-size: 100%;
}

ul {
  padding: 0;
}

main {
  width: 100%;
  position: relative;
  overflow: hidden;
}

body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  color: #000000;
  background: none;
  font-family: "Poppins", helvetica, arial, sans-serif;
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}

fieldset label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

figcaption {
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  figcaption {
    float: left;
  }
}

figcaption > p {
  font-size: 12.5px;
  color: #6c6c6c;
  font-style: italic;
  font-family: "Times New Roman", times, serif;
  float: left;
}

figcaption > p.credit {
  font-style: normal;
  float: right;
}

@media screen and (max-width: 768px) {
  figcaption > p.credit {
    float: left;
  }
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http: //csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 2.25rem;
  font-family: "Poppins", helvetica, arial, sans-serif;
  color: #000000;
  font-weight: 300;
}

h2 {
  font-size: 2.25rem;
  color: #000000;
  font-family: "Poppins", helvetica, arial, sans-serif;
  font-weight: 300;
}

h3 {
  font-size: 1.5rem;
  font-family: "Poppins", helvetica, arial, sans-serif;
  color: #D8D8D8;
}

h4 {
  font-size: 1.5rem;
  color: #D8D8D8;
  font-family: "Poppins", helvetica, arial, sans-serif;
}

h5 {
  font-size: 1.2rem;
  font-family: "Poppins", helvetica, arial, sans-serif;
  color: #000000;
}

h6 {
  font-size: 1.2rem;
  color: #000000;
  font-family: "Poppins", helvetica, arial, sans-serif;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
  width: 100%;
  border-top: 1px solid #D8D8D8;
  color: rgba(0, 0, 0, 0);
  margin-bottom: 15px;
}

hr.e-hr-15 {
  margin: 15px 0;
}

hr.e-hr-25 {
  margin: 25px 0;
}

hr.e-hr-30 {
  margin: 30px 0;
}

hr.e-hr-60 {
  margin: 60px 0;
}

hr.e-hr-mini {
  width: 50px;
  margin-right: auto;
}

hr.e-hr-lightgrey {
  border-color: #D8D8D8;
}

hr.e-hr-hidden {
  border-color: rgba(0, 0, 0, 0);
}

hr.e-hr-thick {
  border-top: 2px solid #D8D8D8;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *  navigating between pages that do/do not have enough content to produce
 *  scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: none;
  color: #000000;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  line-height: 1.5;
  /* [1] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@media (min-width: 768px) {
  html {
    font-size: 0.875em;
    /* 14px */
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 0.9375em;
    /* 15px */
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 1em;
    /* 16px */
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *  elements when they are dropped into a page as-is. Safer alternative to
 *  using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *  not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #ff9c2a;
  text-decoration: none;
  font-weight: 600;
}

a:hover {
  text-decoration: none;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
  margin-left: 15px;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

.modal-header .close {
  position: absolute;
  background: #FFF;
  right: -5px;
  top: 0;
  border: 0;
  background: #FFF;
}

.modal-dialog {
  margin: 175px auto;
}

.modal-header {
  text-align: center;
  border-bottom: 0;
  position: relative;
}

.modal-content {
  border-radius: 0;
  padding: 20px;
}

.hidden-form {
  display: none;
}

.pop-up-close {
  font-size: 22px;
  color: #000;
}

.pop-up-close-svg {
  display: block;
}

.pop-up-close-svg > svg {
  width: 30px;
  height: 30px;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

.o-button {
  cursor: pointer;
  border: none;
  height: 60px;
  width: 245px;
  padding: 18px 65px;
  background-color: #ff9c2a;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.75;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .o-button {
    height: 38px;
    width: 100%;
    padding: 10px 20px;
  }
}

.o-button:hover {
  color: #ff9c2a;
  background-color: #ffffff;
  border: 1px solid #ff9c2a;
  padding: 17px 64px;
}

@media screen and (max-width: 767px) {
  .o-button:hover {
    padding: 9px 19px;
  }
}

.o-button--nobg {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #ff9c2a;
  color: #ff9c2a;
  padding: 17px 84px;
}

.o-button--nobg:hover {
  border: none;
  background-color: #ff9c2a;
  color: #ffffff;
  padding: 18px 85px;
}

@media screen and (max-width: 767px) {
  .o-button {
    height: 38px;
    width: max-content;
    padding: 10px 20px;
    padding: 10px 20px;
    line-height: 1;
  }
}

.o-hr--grey-15 {
  border-top: 1px #6D7278 solid;
  margin: 15px 0;
  width: 100%;
}

.o-hr--grey-25 {
  border-top: 1px #6D7278 solid;
  margin: 25px 0;
  width: 100%;
}

.o-hr--grey-50 {
  border-top: 1px #6D7278 solid;
  margin: 50px 0;
  width: 100%;
}

.o-hr--grey-m-full {
  display: none;
}

@media screen and (max-width: 767px) {
  .o-hr--grey-m-full {
    display: block;
    border-top: 1px #6D7278 solid;
    margin: 15px 0;
    padding: 0;
    width: 100%;
  }
}

.o-hr--grey-half {
  border-top: 1px #6D7278 solid;
  margin: 50px 0;
  width: 30%;
}

.o-hr--white {
  border-color: #ffffff;
}

.o-hr--hidden {
  border: none;
  width: 100%;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http: //www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading: 
 * https: //developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
}

.o-wrapper:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-about__head {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.05;
  color: #ff9c2a;
}

@media screen and (min-width: 768px) {
  .c-about__head {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
}

.c-about__content > p {
  font-size: 16px;
  line-height: 1.75;
  color: #6D7278;
  margin-bottom: 20px;
}

.c-about__company {
  font-size: 14px;
  font-weight: 600;
  color: #ff9c2a;
}

.c-about__website {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  color: #979797;
}

.c-about__arrow {
  transform: rotate(270deg);
  position: absolute;
  right: 45px;
  height: 50px;
  width: 14px;
  top: -5px;
}

.c-archive__head > h1 {
  font-size: 29px;
  font-weight: 600;
  order: 1;
  margin-bottom: 0;
  width: max-content;
  padding-right: 10px;
  background-color: #ffffff;
  z-index: 11;
  position: relative;
}

.c-article__figure {
  height: 494px;
  width: 100%;
  margin-bottom: 10px;
}

.c-article__figure--factcheck {
  height: 398px;
}

@media screen and (max-width: 767px) {
  .c-article__figure {
    height: 240px;
    width: calc(100% + 30px);
    margin: 0 -15px;
  }
}

.c-article__img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.c-article__figcaption {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.58;
  color: #6D7278;
}

.c-article__subject {
  font-family: "Raleway", helvetica, arial, sans-serif;
  font-size: 10.5px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.44px;
  text-transform: uppercase;
  margin-top: 35px;
  margin-bottom: 15px;
}

.c-article__subject--factcheck {
  color: #ff9c2a;
  margin-top: 0;
}

.c-article__title {
  font-size: 37px;
  width: 100%;
  padding-bottom: 28px;
  border-bottom: 1px #000000 solid;
  font-weight: 600;
  line-height: 1.22;
  letter-spacing: 0.06px;
  margin-bottom: 10px;
}

.c-article__date {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.62;
  text-align: right;
}

.c-article__byline {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.62;
  text-align: left;
}

.c-article__social {
  float: left;
  height: 25px;
  margin-top: 15px;
}

.c-article__social__icon {
  width: 17px;
  float: left;
  margin-right: 10px;
}

.c-article__social__icon > svg {
  width: 17px;
}

.c-article__social--factcheck > a > svg > path {
  fill: #ff9c2a;
}

.c-article__content {
  margin-top: 40px;
}

.c-article__content > p {
  font-size: 16px;
  line-height: 1.5;
}

.c-article__content--factcheck h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 0.75;
  color: #ff9c2a;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .c-article__content--factcheck h2 {
    font-size: 17px;
  }
}

.c-article__content--factcheck h2:nth-of-type(even) {
  color: #000000;
}

.c-article__content--factcheck img {
  border: 1px solid #EFEFEF;
}

.c-article__content--factcheck ul > li, .c-article__content--factcheck > li {
  color: #000000;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .c-article__content--factcheck > p {
    font-size: 18px;
    line-height: 29px;
  }
}

.c-article__verdict {
  padding: 50px 30px;
  background-color: #EFEFEF;
}

.c-article__verdict > ul {
  margin: 0;
}

.c-article__verdict > ul > li {
  list-style: none;
  color: #000000;
  margin-left: 0;
  font-weight: 500;
}

.c-article__verdict > ul > li > strong {
  text-transform: uppercase;
  font-size: 17px;
}

.c-article__others-head {
  font-size: 27px;
  font-weight: 600;
  line-height: 1.22;
  color: #000000;
}

.c-contact__head {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.05;
  color: #ff9c2a;
}

@media screen and (min-width: 768px) {
  .c-contact__head {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
}

.c-contact__field {
  height: 50px;
  width: 100%;
  border: #6D7278 1px solid;
  border-radius: 9px;
  padding: 21px 15px;
  margin: 0 0 15px 0;
  font-family: "Raleway", helvetica, arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.13;
}

.c-contact__field::placeholder {
  color: #979797;
  font-weight: 500;
}

.c-contact-dropdown__selected {
  height: 50px;
  width: 100%;
  border: #6D7278 1px solid;
  border-radius: 9px;
  padding: 15px 21px;
  margin: 15px 0;
  position: relative;
  cursor: pointer;
}

.c-contact-dropdown__selecttext {
  font-family: "Raleway", helvetica, arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.13;
}

.c-contact-dropdown__arrow {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 21px;
  top: 15px;
}

.c-contact-dropdown__arrow--active {
  transform: rotate(180deg);
  right: 28px;
  top: 18px;
}

.c-contact-dropdown__list {
  width: calc(100% - 60px);
  position: absolute;
  z-index: 1;
  margin-top: -15px;
}

.c-contact-dropdown__listitem {
  list-style: none;
  height: 50px;
  width: 100%;
  border: #6D7278 1px solid;
  padding: 15px 21px;
  margin: 0;
  font-family: "Raleway", helvetica, arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.13;
  color: #6D7278;
  background-color: #ffffff;
  cursor: pointer;
}

.c-contact-dropdown__listitem--selected {
  background-color: #D8D8D8;
  color: #ff9c2a;
}

.c-contact__submit {
  background-color: #ff9c2a;
  color: #ffffff;
  padding: 9px 35px;
  font-size: 16px;
  border: none;
  font-weight: 700;
  cursor: pointer;
}

.c-contact__submit:hover {
  padding: 8px 34px;
  border: 1px solid #ff9c2a;
  background-color: #ffffff;
  color: #ff9c2a;
}

.c-contact__submit--disabled {
  background-color: #D8D8D8;
  cursor: unset;
}

.c-contact__submit--disabled:hover {
  border: none;
  background-color: #D8D8D8;
  color: #ffffff;
}

.c-contact__textarea {
  width: 100%;
  border: #6D7278 1px solid;
  padding: 10px;
  margin: 15px 0;
  max-width: 100%;
  min-width: 100%;
  font-family: "Raleway", helvetica, arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.13;
}

.c-contact__textarea::placeholder {
  color: #979797;
  font-weight: 500;
}

.c-contactbar {
  padding: 25px 0 35px;
  background-color: #0A1726;
}

@media screen and (min-width: 992px) {
  .c-contactbar {
    padding: 70px 0;
  }
}

.c-contactbar__head {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.38;
  color: #ffffff;
}

@media screen and (max-width: 767px) {
  .c-contactbar__head {
    text-align: center;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 768px) {
  .c-contactbar__head {
    font-size: 26px;
  }
}

.c-contactbar__text {
  font-size: 15px;
  line-height: 1.11;
  color: #D8D8D8;
}

@media screen and (max-width: 767px) {
  .c-contactbar__text {
    text-align: center;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 768px) {
  .c-contactbar__text {
    font-size: 18px;
  }
}

.c-contactbar__button {
  margin: 0 0 0 auto;
}

@media screen and (max-width: 767px) {
  .c-contactbar__button {
    margin: 0 auto;
  }
}

.c-footer-menu__col {
  width: max-content;
  margin-right: 30px;
}

@media screen and (max-width: 767px) {
  .c-footer-menu__col {
    width: 100%;
    margin: 0 auto 0 30px;
  }
  .c-footer-menu__col:first-child {
    margin-top: 30px;
  }
}

.c-footer-menu__li {
  list-style: none;
}

@media screen and (max-width: 767px) {
  .c-footer-menu__li {
    margin-bottom: 0;
    margin-left: 2px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .c-footer-menu__li {
    margin-bottom: 0;
    margin-left: 0;
  }
}

.c-footer-menu__li--nochild {
  margin-bottom: 18px;
}

.c-footer-menu__link {
  font-family: "Raleway", helvetica, arial, sans-serif;
  color: #ffffff;
  margin-bottom: 5px;
}

@media screen and (max-width: 991px) {
  .c-footer-menu__link {
    font-size: 12px;
  }
}

.c-footer-menu__link:hover {
  color: #ff9c2a;
}

.c-footer-menu__link--main {
  font-weight: 500;
}

.c-footer-menu__link--child {
  font-weight: 400;
}

.c-footer-menu__link--blue {
  color: #ff9c2a;
}

.c-footer-menu__link--head {
  font-weight: 700;
}

.c-footer-menu__link--head:hover {
  color: #6D7278;
}

.c-footer-menu__link--login {
  position: relative;
  margin-bottom: 10px;
}

.c-footer-menu__link--login:after {
  content: '';
  width: 100%;
  height: 3px;
  background-color: #ff9c2a;
  position: absolute;
  display: block;
  bottom: -5px;
}

.c-footer-menu__link > strong {
  font-weight: 600;
}

.c-footer-menu__text {
  font-family: "Raleway", helvetica, arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.67;
  color: #ffffff;
}

.c-footer-menu__legal__item {
  float: left;
  padding-left: 15px;
  padding-right: 10px;
}

.c-footer-menu__legal__item > a, .c-footer-menu__legal__item p {
  font-family: "Poppins", helvetica, arial, sans-serif;
  font-size: 13px;
  letter-spacing: 0.44px;
  color: #ffffff;
  font-weight: 400;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .c-footer-menu__legal__item > a, .c-footer-menu__legal__item p {
    font-size: 11px;
    letter-spacing: 0;
  }
}

@media screen and (max-width: 767px) {
  .c-footer-menu__legal__item > a, .c-footer-menu__legal__item p {
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 0.38px;
    color: #ffffff;
  }
}

.c-footer-menu__legal__item:before {
  content: '';
  height: 12px;
  width: 1px;
  background-color: #6D7278;
  display: block;
  position: absolute;
  margin: 7px 0 0 -13px;
}

.c-footer-menu__legal__item:last-child > p {
  margin-top: 3px;
}

.c-footer-menu__legal__item:first-child {
  padding-left: 0;
}

.c-footer-menu__legal__item:first-child:before {
  display: none;
}

.c-hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../assets/images/hero.png");
  background-color: #0A1726;
  padding: 150px 0 250px;
}

@media screen and (max-width: 767px) {
  .c-hero {
    padding: 100px 0 100px;
  }
}

.c-hero__icon {
  width: 111px;
  height: 52px;
  padding: 65px 0;
}

.c-hero__title {
  width: 100%;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.25;
  color: #ffffff;
  margin-bottom: 0;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .c-hero__title {
    font-size: 40px;
    margin-top: -10px;
  }
}

@media screen and (min-width: 992px) {
  .c-hero__title {
    font-size: 52px;
    margin-top: -15px;
  }
}

.c-hero__downarrow {
  width: 100%;
}

.c-hero__downarrow > svg {
  display: block;
  margin: 0 auto;
}

.c-hero__buttoncont {
  position: relative;
}

.c-hero__button {
  display: block;
  margin: 0;
  height: 40px;
  padding: 11px 29px;
  width: max-content;
  font-size: 16px;
  line-height: 1;
}

.c-hero__button:hover {
  padding: 10px 28px;
}

.c-index-panel {
  width: 100%;
  padding: 60px 0 0;
}

.c-index-panel__head {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.05;
  color: #ff9c2a;
}

@media screen and (min-width: 768px) {
  .c-index-panel__head {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    height: min-content;
    min-height: 180px;
  }
}

.c-index-panel__excerpt {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.43;
  color: #D8D8D8;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .c-index-panel__excerpt {
    font-size: 30px;
    line-height: 1.33;
  }
}

@media screen and (min-width: 992px) {
  .c-index-panel__excerpt {
    font-size: 40px;
    line-height: 1.25;
  }
}

.c-index-panel--whitebg {
  background: #ffffff;
}

.c-index-panel--greybg {
  background-color: #EFEFEF;
}

.c-index-panel--greybg .c-index-panel__excerpt {
  color: #6D7278;
}

.c-index-panel__text > p {
  font-family: "Raleway", helvetica, arial, sans-serif;
  font-size: 15px;
  line-height: 1.75;
  color: #6D7278;
  margin-bottom: 10px;
}

@media screen and (min-width: 992px) {
  .c-index-panel__text > p {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
}

.c-index-panel__downarrow {
  margin-left: 15px;
}

.c-index-panel__rightarrow {
  float: left;
  transform: rotate(270deg);
  margin-left: 30px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .c-index-panel__rightarrow {
    margin-left: 200px;
    margin-top: 5px;
  }
}

.c-index-panel__button {
  float: left;
  margin-left: 45px;
  margin-top: 10px;
  padding: 10px 20px;
}

.c-index-panel__button:hover {
  padding: 11px 21px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .c-index-panel__button {
    width: unset;
    height: unset;
  }
}

@media screen and (min-width: 992px) {
  .c-index-panel__button {
    margin-left: 16%;
    width: unset;
    height: unset;
  }
}

.c-index-panel__carousel .owl-item {
  padding-bottom: 0 !important;
}

.c-index-panel__carousel .owl-dots {
  position: absolute;
}

@media screen and (max-width: 767px) {
  .c-index-panel__carousel .owl-dots {
    width: max-content;
    right: 15px;
    bottom: -20px;
  }
}

@media screen and (min-width: 768px) {
  .c-index-panel__carousel .owl-dots {
    right: -12.5%;
    bottom: -75px;
    height: max-content;
    width: 6px;
  }
}

@media screen and (min-width: 992px) {
  .c-index-panel__carousel .owl-dots {
    right: -8.5%;
    bottom: 25px;
  }
}

.c-index-panel__carousel .owl-dot {
  width: 26px;
  height: 4px;
  background-color: #D8D8D8 !important;
  margin-right: 3px;
}

@media screen and (min-width: 768px) {
  .c-index-panel__carousel .owl-dot {
    margin-right: 0;
    width: 4px;
    height: 26px;
  }
}

@media screen and (min-width: 992px) {
  .c-index-panel__carousel .owl-dot {
    margin-bottom: -3px;
  }
}

.c-index-panel__carousel .owl-dot.active {
  background-color: #ff9c2a !important;
}

.c-index-panel__singleimage {
  width: 100%;
}

.c-index-panel__figure {
  width: 100%;
  margin-bottom: 0;
}

.c-index-panel__image {
  width: 100%;
}

.c-index-panel__imagetitle {
  font-size: 10px;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .c-index-panel__imagetitle {
    border-bottom: 1px solid #D8D8D8;
  }
}

@media screen and (min-width: 768px) {
  .c-index-panel__imagetitle {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    height: 383px;
    border-left: 1px solid #D8D8D8;
    font-size: 14px;
  }
}

@media screen and (min-width: 992px) {
  .c-index-panel__imagetitle {
    height: 443px;
  }
}

.c-index-partners {
  position: relative;
  padding: 80px 10vw 60px;
  background-color: #EFEFEF;
}

.c-index-partners__headbar {
  background-color: #ffffff;
  position: relative;
}

.c-index-partners__headbar:after {
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  right: calc(50% - 35px);
  bottom: -23px;
  font-size: 2.1875rem;
  line-height: 2.625rem;
  line-height: 1;
  transform: rotate(45deg);
  z-index: 1;
}

.c-index-partners__top {
  font-size: 12px;
  font-weight: 700;
  line-height: 1.67;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
}

.c-index-partners__head {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.42;
  text-align: center;
  color: #ff9c2a;
  margin-bottom: 0;
}

@media screen and (min-width: 992px) {
  .c-index-partners__head {
    font-size: 31px;
  }
}

.c-index-partners__logo {
  max-width: 146px;
  max-height: 41px;
}

@media screen and (min-width: 992px) {
  .c-index-partners__logo {
    max-width: 180px;
    max-height: 50px;
  }
}

.c-index-partners__logo--vsg {
  max-height: 38px;
}

@media screen and (min-width: 992px) {
  .c-index-partners__logo--vsg {
    max-height: 46px;
  }
}

.c-index-partners__logo--ausp {
  max-height: 35px;
}

@media screen and (min-width: 992px) {
  .c-index-partners__logo--ausp {
    max-height: 41px;
  }
}

.c-index-partners__logo--wow {
  max-height: 49px;
}

@media screen and (min-width: 992px) {
  .c-index-partners__logo--wow {
    max-height: 56px;
  }
}

.c-index-partners__logo--wbc {
  max-height: 26px;
}

@media screen and (min-width: 992px) {
  .c-index-partners__logo--wbc {
    max-height: 30px;
    margin-top: 15px;
  }
}

.c-index-partners__logo--da {
  max-height: 50px;
}

@media screen and (min-width: 992px) {
  .c-index-partners__logo--da {
    max-height: 57px;
  }
}

.c-index-partners__logo--nine {
  max-height: 28px;
}

@media screen and (min-width: 992px) {
  .c-index-partners__logo--nine {
    max-height: 33px;
  }
}

.c-index-partners__logo--ato {
  max-height: 65px;
}

@media screen and (min-width: 992px) {
  .c-index-partners__logo--ato {
    max-height: 77px;
  }
}

.c-index-partners__logo--cos {
  max-height: 28px;
}

@media screen and (min-width: 992px) {
  .c-index-partners__logo--cos {
    max-height: 32px;
    margin-top: 15px;
  }
}

.c-index-partners__logo--mbv {
  max-height: 44px;
}

@media screen and (min-width: 992px) {
  .c-index-partners__logo--mbv {
    max-height: 50px;
  }
}

.c-index-partners__logo--unsw {
  max-height: 44px;
}

@media screen and (min-width: 992px) {
  .c-index-partners__logo--unsw {
    max-height: 50px;
  }
}

.c-index-partners__logo > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.c-index-quotes {
  padding: 50px 0 0;
  background-color: #0A1726;
}

@media screen and (min-width: 992px) {
  .c-index-quotes {
    padding: 70px 0 0;
  }
}

.c-index-quotes__container {
  position: relative;
}

.c-index-quotes__overlay {
  width: 194px;
  height: 130px;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (min-width: 992px) {
  .c-index-quotes__overlay {
    width: 310px;
    height: 203px;
  }
}

.c-index-quotes__overlay > svg {
  width: 100%;
  height: 100%;
}

.c-index-quotes__quotetext {
  padding-top: 45px;
}

.c-index-quotes__quotetext > p {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.55;
  color: #ffffff;
}

@media screen and (min-width: 992px) {
  .c-index-quotes__quotetext > p {
    font-size: 30px;
  }
}

.c-index-quotes__author {
  font-size: 12px;
  line-height: 1.5;
  color: #ffffff;
}

@media screen and (min-width: 992px) {
  .c-index-quotes__author {
    font-size: 16px;
  }
}

.c-index-quotes__position {
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  color: #ffffff;
}

@media screen and (min-width: 992px) {
  .c-index-quotes__position {
    font-size: 16px;
  }
}

.c-index-quotes__carousel .owl-dots {
  position: absolute;
  left: 0;
  top: 0;
}

.c-index-quotes__carousel .owl-dot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #ffffff !important;
  margin-right: 3px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .c-index-quotes__carousel .owl-dot {
    margin-right: 6px;
  }
}

.c-index-quotes__carousel .owl-dot.active {
  background-color: #ff9c2a !important;
}

.c-login-form__title {
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.62px;
  color: #ff9c2a;
  margin-bottom: 20px;
}

.c-login-form__input {
  width: 100%;
  height: 48px;
  border: solid 1px #D8D8D8;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.06;
  letter-spacing: 0.55px;
  color: #6D7278;
}

.c-login-form__button {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.75;
  text-align: center;
  color: #ffffff;
}

.c-login-modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.c-login-modal__close {
  right: 0;
}

.c-login-modal__icon > svg {
  width: 15px;
  height: 15px;
}

.c-login-modal__head {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.8;
  letter-spacing: 0.62px;
  color: #000000;
  margin-bottom: 15px;
  text-align: center;
}

.c-login-modal__input {
  width: 100%;
  height: 50px;
  border-radius: 9px;
  border: solid 1px #D8D8D8;
  padding: 16px 21px;
  font-family: "Raleway", helvetica, arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.13;
}

.c-login-modal__input::placeholder {
  font-weight: 600;
  color: #D8D8D8;
}

.c-login-modal__forgot {
  font-family: "Raleway", helvetica, arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #6D7278;
  text-decoration: underline;
}

.c-login-modal__submit {
  padding: 9px 40px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  width: max-content;
  display: block;
  margin: 0 auto;
}

.c-login-modal__submit:hover {
  padding: 8px 39px;
}

.c-media-panel {
  position: relative;
}

.c-media-panel:nth-child(2) {
  border-top: 15px #EFEFEF solid;
  border-left: 15px #EFEFEF solid;
}

.c-media-panel:nth-child(2):after {
  content: '';
  position: absolute;
  left: -25px;
  bottom: -10px;
  width: 22px;
  height: 22px;
  transform: rotate(45deg);
  background-color: #ffffff;
}

.c-media-panel:nth-child(2) > .container {
  padding-left: 15px;
}

.c-media-panel__head {
  font-size: 35px;
  font-weight: 700;
  line-height: 1.2;
  color: #D8D8D8;
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

@media screen and (max-width: 767px) {
  .c-media-panel__head {
    height: min-content;
  }
}

@media screen and (min-width: 768px) {
  .c-media-panel__head {
    writing-mode: unset;
  }
}

.c-media-panel__figure {
  width: calc(100% + 30px);
  margin-right: -30px;
}

.c-media-panel__image {
  width: 100%;
}

.c-media-panel__imagetitle {
  font-size: 20px;
  font-weight: 600;
  color: #ff9c2a;
}

.c-media-panel__text > p {
  font-family: "Raleway", helvetica, arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
}

@media screen and (min-width: 992px) {
  .c-media-panel__text > p {
    font-size: 16px;
  }
}

.c-media-panel__icon {
  margin-top: 10px;
}

.c-media-panel__blurbtitle {
  font-size: 14px;
  font-weight: 700;
  color: #ff9c2a;
  margin-bottom: 0;
}

.c-media-panel__blurb {
  font-family: "Raleway", helvetica, arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.67;
  color: #6D7278;
}

.c-media__head {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.05;
  color: #ff9c2a;
  padding-right: 45px;
}

.c-media__snippet {
  font-size: 20px;
  font-weight: 600;
  color: #6D7278;
}

.c-media__content > p {
  font-size: 16px;
  line-height: 1.75;
  color: #6D7278;
  margin-bottom: 20px;
}

.c-media__blurbtitle {
  font-family: "Raleway", helvetica, arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #ff9c2a;
  margin-bottom: 0;
}

.c-media__blurb {
  font-family: "Raleway", helvetica, arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.67;
  color: #6D7278;
}

.owl-carousel .owl-item img {
  height: 100%;
  width: 100%;
}

.owl-stage-outer {
  height: 100%;
}

.owl-next {
  float: right;
}

.owl-prev > div > svg > g, .owl-next > div > svg > g {
  stroke: #ffffff;
}

.owl-prev.disabled > div > svg > g, .owl-next.disabled > div > svg > g {
  stroke: #6D7278;
}

.owl-item {
  height: 100%;
  padding-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .owl-item {
    padding-bottom: 30px;
  }
}

.owl-theme .owl-controls .owl-page {
  display: inline-block;
}

.owl-pagination {
  display: none;
}

.owl-nav {
  width: 100%;
  position: absolute;
  top: 250px;
}

@media screen and (min-width: 992px) and (max-width: 1349px) {
  .owl-nav {
    top: 150px;
  }
}

@media screen and (max-width: 767px) {
  .owl-nav {
    top: 95px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .owl-nav {
    top: 120px;
  }
}

.owl-theme .owl-controls .owl-page span {
  display: block;
  width: 19px;
  padding: 0;
  border-bottom: solid 1px #ffffff;
  opacity: 0.99;
  float: right;
  margin: 0;
  cursor: pointer;
}

.owl-theme .owl-controls .owl-page span:before {
  content: '•';
  font-size: 40px;
  font-weight: 700;
  color: #ffffff;
  opacity: 0.99;
  text-shadow: -1.5px -1.5px 0 #000, 1.5px -1.5px 0 #000, -1.5px 1.5px 0 #000, 1.5px 1.5px 0 #000;
  -webkit-text-stroke: 1.5px white;
}

.owl-theme .owl-controls .active span {
  opacity: 0.99;
}

.owl-theme .owl-controls .active span:before {
  color: #ff9c2a;
}

.c-page__head > h1 {
  font-size: 29px;
  font-weight: 600;
  order: 1;
  margin-bottom: 0;
  width: max-content;
  padding-right: 10px;
  background-color: #ffffff;
  z-index: 11;
  position: relative;
}

.c-page__head > h1 > strong {
  color: #ff9c2a;
}

.c-page__subhead {
  margin-top: 10px;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0.38px;
}

.c-page__content > p {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.77px;
  color: #000000;
  margin-bottom: 15px;
}

.c-page__content > p > strong {
  font-weight: 700;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .c-page__content > p {
    font-size: 15px;
  }
}

.c-page__content > .wp-block-image > img {
  max-width: 100%;
}

.c-page__content h1, .c-page__content h2, .c-page__content h3, .c-page__content h4 {
  color: #000000;
}

.c-page__content > iframe {
  width: 100%;
  margin: 0px;
  border: none;
  display: block;
  height: 442px;
  overflow: hidden;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .c-page__content > iframe {
    height: 700px;
  }
}

@media screen and (max-width: 767px) {
  .c-page__content > iframe {
    height: 1000px;
  }
}

.c-products {
  padding: 50px 0;
  background-color: #0A1726;
}

.c-products__head {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.25;
  color: #ff9c2a;
  text-align: center;
}

@media screen and (min-width: 992px) {
  .c-products__head {
    font-size: 40px;
    margin-bottom: 10px;
  }
}

.c-products__subhead {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.38;
  color: #EFEFEF;
  text-align: center;
}

@media screen and (min-width: 992px) {
  .c-products__subhead {
    font-size: 26px;
  }
}

@media screen and (max-width: 767px) {
  .c-products-card {
    padding: 10px 0;
    margin-bottom: 25px;
  }
}

.c-products-card__icon {
  height: 35px;
}

@media screen and (max-width: 991px) {
  .c-products-card__icon > svg {
    margin: 0 auto;
    display: block;
  }
}

.c-products-card__head {
  font-size: 14px;
  font-weight: 600;
  color: #ff9c2a;
}

@media screen and (max-width: 991px) {
  .c-products-card__head {
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .c-products-card__head {
    font-size: 20px;
    letter-spacing: -0.1px;
  }
}

.c-products-card__text {
  display: none;
}

@media screen and (min-width: 768px) {
  .c-products-card__text {
    display: block;
  }
  .c-products-card__text > p {
    font-size: 14px;
    color: #ffffff;
    font-family: "Raleway", helvetica, arial, sans-serif;
    font-weight: 500;
    line-height: 1.71;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .c-products-card__text > p {
    text-align: center;
    padding: 0 15px;
  }
}

.c-site-footer {
  width: 100%;
  padding-top: 28px;
  background-color: #0A1726;
}

@media screen and (min-width: 992px) {
  .c-site-footer {
    padding: 15px 50px;
    border-top: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .c-site-footer {
    padding: 30px;
    height: auto;
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .c-site-footer {
    padding: 32px 0;
    height: auto;
  }
}

.c-site-footer__copyright {
  font-size: 12px;
  color: #ffffff;
  text-align: right;
  width: max-content;
  float: right;
  font-weight: 400;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .c-site-footer__copyright {
    font-size: 11px;
    letter-spacing: 0;
  }
}

@media screen and (max-width: 767px) {
  .c-site-footer__copyright {
    margin-top: 5px;
    font-size: 11px;
    font-weight: 300;
    margin-bottom: 25px;
    float: left;
  }
}

.c-site-footer__copyright > p {
  font-family: "Poppins", helvetica, arial, sans-serif;
  font-size: 13px;
  letter-spacing: 0.44px;
  color: #ffffff;
  font-weight: 400;
}

@media screen and (max-width: 767px) {
  .c-site-footer__copyright > p {
    font-size: 11px;
    font-weight: 400;
  }
}

.showOnScroll {
  top: 0 !important;
}

.site-header {
  height: 130px;
  position: relative;
  padding: 0;
  background-color: #fff;
  z-index: 140;
  width: 100%;
}

@media screen and (min-width: 992px) {
  .site-header {
    padding: 15px 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .site-header {
    height: 80px;
    position: fixed;
    top: 0;
  }
}

@media screen and (max-width: 767px) {
  .site-header {
    height: 79px;
    position: fixed;
    top: 0;
  }
}

@media screen and (max-width: 991px) {
  .site-header__container {
    padding: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .site-header__container {
    max-width: 100%;
    padding: 0 25px;
  }
}

@media screen and (max-width: 991px) {
  .site-header--dark {
    background: #000000;
    background-image: url(../assets/images/dots.png);
    background-repeat: repeat-y;
    background-position: right;
    background-size: 40%;
  }
}

@media screen and (max-width: 767px) {
  .site-header--dark {
    background-size: 60%;
  }
}

.navigation-logo {
  z-index: 500;
  float: left;
  margin-top: 25px;
}

@media screen and (max-width: 991px) {
  .navigation-logo {
    margin-top: 20px;
    margin-left: 15px;
  }
}

.navigation-logo__link {
  display: block;
  padding: 0;
}

.navigation-logo__img {
  height: 49px;
}

@media screen and (max-width: 767px) {
  .navigation-logo__img {
    height: 40px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .navigation-logo__img {
    height: 45px;
  }
}

.navigation-logo__img--dark svg > g {
  fill: #ffffff;
}

.site-nav {
  margin: 0;
  list-style: none;
  height: 102px;
  padding: 30px 0 0;
  white-space: nowrap;
}

@media screen and (max-width: 991px) {
  .site-nav {
    height: auto;
    border-bottom: 0;
    padding: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .site-nav {
    float: right;
    margin-right: 35px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) {
  .site-nav {
    position: relative;
  }
}

@media screen and (max-width: 991px) {
  .site-nav ul.mobile {
    display: none;
  }
}

.site-nav__menu-hamburger {
  display: none;
  z-index: 999;
}

@media screen and (max-width: 767px) {
  .site-nav__menu-hamburger {
    position: absolute;
    right: 25px;
    top: 25px;
    height: 40px;
  }
}

@media screen and (max-width: 991px) {
  .site-nav__menu-hamburger {
    display: block;
  }
}

.site-nav__menu-hamburger--closed {
  display: block;
}

@media screen and (max-width: 767px) {
  .site-nav__menu-hamburger--closed > svg {
    height: 25px;
    width: 25px;
  }
}

.site-nav__menu-hamburger--open {
  display: none;
}

.site-nav__menu-hamburger__cross {
  padding-right: 2px;
  padding-top: 2px;
}

.site-nav__menu-hamburger__cross > svg {
  position: relative;
  width: 20px;
  height: 20px;
}

.site-menu {
  float: right;
  position: relative;
}

@media screen and (max-width: 991px) {
  .site-menu {
    display: none;
  }
  .site-menu.site-menu--open {
    display: block;
    position: absolute;
    width: 100%;
    background-color: #ffffff;
    top: 75px;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .site-menu.site-menu--open {
    background-size: 40%;
    right: 0;
    border-top: none;
    border-right: none;
    border-left: none;
    top: 80px;
  }
}

@media screen and (max-width: 991px) and (max-width: 767px) {
  .site-menu.site-menu--open {
    height: min-content;
    overflow: scroll;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .site-menu {
    top: 95px;
  }
}

@media screen and (max-width: 767px) {
  .site-menu {
    top: 89px;
  }
}

.site-menu__item {
  display: inline-block;
  margin: 0;
  position: relative;
  height: 100%;
}

@media screen and (min-width: 992px) {
  .site-menu__item {
    margin-left: 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .site-menu__item {
    display: block;
    min-height: 66px;
    width: auto;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    float: none;
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .site-menu__item {
    display: block;
    width: auto;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    float: none;
    display: block;
  }
}

.site-menu__link {
  font-family: "Raleway", helvetica, arial, sans-serif;
  font-size: 18px;
  height: 100%;
  display: block;
  font-weight: 600;
  color: #000000;
  position: relative;
  padding: 0 20px 0 0;
  letter-spacing: 0.45px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .site-menu__link {
    font-size: 18px;
    padding: 20px 25px;
    font-weight: 600;
    margin-right: 100px;
    color: #000000;
    min-height: 66px;
  }
}

@media screen and (max-width: 767px) {
  .site-menu__link {
    font-size: 16px;
    padding: 10px 25px;
    font-weight: 600;
    margin-right: 50px;
    color: #000000;
  }
}

.site-menu__link:hover {
  text-decoration: none;
}

.site-menu__link--login:after {
  content: '';
  width: calc(100% - 20px);
  height: 3px;
  background-color: #ff9c2a;
  position: absolute;
  display: block;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .site-menu__link--login:after {
    width: 55px;
  }
}

@media screen and (max-width: 767px) {
  .site-menu__link--login:after {
    width: 44px;
  }
}

.site-menu__link--button {
  height: 40px;
  padding: 11px 29px;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
}

.site-menu__link--button:hover {
  padding: 10px 28px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .site-menu__link--button {
    min-height: unset;
    width: min-content;
    margin: 0;
    position: absolute;
    right: 120px;
    top: 23px;
  }
}

.site-menu__item--current {
  color: #D8D8D8;
}

.site-menu__search {
  height: 19px;
  margin-bottom: -3px;
}

.site-menu__search > svg {
  height: 100%;
}

.site-menu__container {
  margin-bottom: 0;
}

.site-menu__overlay {
  background-color: #000000;
  opacity: 0.55;
  z-index: -1;
  height: 1500px;
  width: 100%;
  position: absolute;
  top: 117px;
}

@media screen and (min-width: 992px) {
  .site-menu__overlay {
    display: none;
  }
}

.site-menu__quote {
  position: absolute;
  bottom: 30px;
  padding: 0 25px;
  width: 70%;
}

@media screen and (max-width: 767px) {
  .site-menu__quote {
    position: fixed;
  }
}

.site-menu__quote__head {
  color: #ff9c2a;
  font-size: 15px;
  font-weight: 700;
}

.site-menu__quote__text {
  font-size: 25px;
  font-weight: 700;
  color: #ffffff;
  word-wrap: break-word;
}

.site-menu__quote__author {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
}

.site-sub-menu {
  z-index: 900;
  display: none;
  position: absolute;
  list-style: none;
  margin: 0;
  min-width: 278px;
  width: 278px;
  left: 0;
  top: 30.5px;
  margin-top: 0;
  box-shadow: 0 10px 8px 2px rgba(109, 114, 120, 0.2);
  padding: 10px 0;
}

.site-sub-menu:before {
  content: '';
  background: rgba(0, 0, 0, 0);
  height: 11px;
  width: 100%;
  position: absolute;
  top: -3px;
}

@media screen and (max-width: 991px) {
  .site-sub-menu {
    height: auto;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    bottom: 0;
    position: static;
    box-shadow: none;
  }
}

@media screen and (max-width: 991px) {
  .site-sub-menu {
    left: auto;
    width: 100%;
    margin: 0;
    top: 0;
  }
}

.site-sub-menu__item {
  height: 100%;
  text-align: left;
  margin-bottom: 0;
  margin-left: 0;
  color: #000000;
  background: #ffffff;
  padding: 0 20px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .site-sub-menu__item {
    margin: 0;
    position: relative;
    display: block;
    height: auto;
    padding-left: 30px;
    background: #ffffff;
    min-height: 50px;
    padding: 0 0 0 30px;
    width: auto;
  }
  .site-sub-menu__item:first-child {
    padding-top: 10px;
  }
  .site-sub-menu__item:last-child {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .site-sub-menu__item {
    margin: 0;
    position: relative;
    display: block;
    height: auto;
    padding-left: 30px;
    background: #ffffff;
    padding: 0;
    width: auto;
  }
}

.site-sub-menu__link {
  font-size: 13px;
  padding: 10px;
  display: block;
  font-weight: 500;
  color: #000000;
  letter-spacing: 0.4px;
  text-align: left;
  max-width: 260px;
  overflow: hidden;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .site-sub-menu__link {
    font-size: 14px;
    height: 100%;
    display: block;
    font-weight: 300;
    position: relative;
    padding: 17px 15px 17px 25px;
    text-align: left;
    max-width: unset;
    letter-spacing: 0.48px;
  }
}

@media screen and (max-width: 767px) {
  .site-sub-menu__link {
    font-size: 14px;
    height: 100%;
    display: block;
    font-weight: 300;
    position: relative;
    padding: 10px 15px 10px 25px;
    text-align: left;
    max-width: unset;
    letter-spacing: 0.48px;
    height: 39.5px;
  }
}

.site-sub-menu__link:hover {
  text-decoration: none;
  color: #ff9c2a;
}

.site-sub-menu > li:last-child > a {
  border-bottom: none;
}

.secondary-menu {
  display: none;
}

.site-menu--selected:before {
  content: '';
  position: absolute;
  height: 48px;
  width: 14px;
  top: -52px;
  left: 0;
  background-color: #ff9c2a;
}

@media screen and (max-width: 991px) {
  .site-menu--selected:before {
    top: unset;
    left: 0;
  }
}

.site-menu--selected-hamburger > .site-nav__menu-hamburger--closed {
  display: none;
}

.site-menu--selected-hamburger > .site-nav__menu-hamburger--open {
  display: block;
}

.site-menu--selected > .site-sub-menu {
  display: block;
}

@media screen and (max-width: 991px) {
  .site-menu--selected > .site-sub-menu {
    position: inherit;
  }
}

.site-menu--selected.site-menu__item--nobg {
  background-color: rgba(0, 0, 0, 0);
}

.site-sub-menu__expand {
  float: right;
  width: 50px;
  height: 66px;
  padding: 25px 75px 25px 15px;
  transition: transform ease-in;
}

.site-sub-menu__expand > svg > path {
  fill: #D8D8D8;
}

@media screen and (max-width: 767px) {
  .site-sub-menu__expand {
    width: 39.5px;
    height: 39.5px;
    padding: 10px 15px;
    position: absolute;
    right: 17px;
  }
  .site-sub-menu__expand > svg {
    height: 20px;
  }
}

.site-sub-menu__expanded > svg {
  transform: rotate(90deg);
}

.site-sub-menu__expanded > svg > path {
  fill: #000000;
}

.site-sub-menu__noexpand {
  float: right;
  width: 50px;
  height: 50px;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 1px solid #ccc;
  border-top-color: #07d;
  animation: spinner .6s linear infinite;
}

.c-team__image {
  width: 100%;
}

.c-team__person {
  font-size: 26px;
  font-weight: 200;
  line-height: 1.08;
  letter-spacing: normal;
  color: #6D7278;
  margin-bottom: 10px;
}

.c-team__head {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.08;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 10px;
}

.c-team__position {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #000000;
}

.c-team__description > p {
  font-size: 14px;
  line-height: 1.75;
  color: #6D7278;
}

@media screen and (min-width: 992px) {
  .card-1__desktop {
    position: relative;
  }
  .card-1__desktop a.card > article {
    position: relative;
  }
  .card-1__desktop a.card > article figure {
    margin-bottom: 10px;
  }
  .card-1__desktop a.card > article > .a-content {
    padding: 0;
    position: relative;
  }
  .card-1__desktop a.card > article > .a-content > div {
    display: none;
  }
  .card-1__desktop a.card > article > .a-content > h2 {
    text-align: left;
    font-family: "Raleway", helvetica, arial, sans-serif;
    color: #000000;
  }
  .card-1__desktop a.card > article > .a-content > p.a-excerpt {
    display: none;
  }
  .card-1__desktop a.card {
    margin-bottom: 40px;
  }
  .card-1__desktop a.card > article > figure {
    width: 100%;
    height: 223px;
    float: none;
  }
  .card-1__desktop a.card > article > .content {
    display: block;
  }
  .card-1__desktop a.card > article > .a-content {
    position: relative;
  }
  .card-1__desktop a.card > article > .a-content > h2 {
    margin-top: 15px;
    font-size: 18px;
    max-height: 84px;
    margin-bottom: 5px;
    font-weight: 500;
  }
  .card-1__desktop a.card > article > .a-content > .a-excerpt {
    font-size: 13px;
    max-height: 50px;
    overflow: hidden;
    margin-top: 15px;
  }
  .card-1__desktop a.card.card-noimage {
    padding: 75px 20px 0;
  }
  .card-1__desktop a.card.card-noimage p.a-excerpt {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .card-1__tablet {
    position: relative;
  }
  .card-1__tablet a.card > article {
    position: relative;
  }
  .card-1__tablet a.card > article figure {
    margin-bottom: 10px;
  }
  .card-1__tablet a.card > article > .a-content {
    padding: 0;
    position: relative;
  }
  .card-1__tablet a.card > article > .a-content > div {
    display: none;
  }
  .card-1__tablet a.card > article > .a-content > h2 {
    text-align: left;
    font-family: "Raleway", helvetica, arial, sans-serif;
    color: #000000;
  }
  .card-1__tablet a.card > article > .a-content > p.a-excerpt {
    display: none;
  }
  .card-1__tablet a.card {
    margin-bottom: 40px;
  }
  .card-1__tablet a.card > article > figure {
    width: 100%;
    height: 134px;
    float: none;
  }
  .card-1__tablet a.card > article > .content {
    display: block;
  }
  .card-1__tablet a.card > article > .a-content {
    height: 65px;
    position: relative;
  }
  .card-1__tablet a.card > article > .a-content > h2 {
    margin-top: 10px;
    font-size: 16px;
    max-height: 84px;
    margin-bottom: 10px;
    font-weight: 500;
    line-height: normal;
  }
  .card-1__tablet a.card > article > .a-content > .a-excerpt {
    font-size: 15px;
    max-height: 20px;
    overflow: hidden;
  }
  .card-1__tablet a.card.card-noimage {
    padding: 75px 20px 0;
  }
  .card-1__tablet a.card.card-noimage p.a-excerpt {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .card-1__mobile {
    position: relative;
  }
  .card-1__mobile a.card > article {
    position: relative;
  }
  .card-1__mobile a.card > article figure {
    margin-bottom: 10px;
  }
  .card-1__mobile a.card > article > .a-content {
    padding: 0;
    position: relative;
  }
  .card-1__mobile a.card > article > .a-content > div {
    display: none;
  }
  .card-1__mobile a.card > article > .a-content > h2 {
    text-align: left;
    font-family: "Raleway", helvetica, arial, sans-serif;
    color: #000000;
  }
  .card-1__mobile a.card > article > .a-content > p.a-excerpt {
    display: none;
  }
  .card-1__mobile a.card {
    margin-bottom: 30px;
  }
  .card-1__mobile a.card > article > figure {
    width: 100%;
    height: 165px;
    float: none;
  }
  .card-1__mobile a.card > article > .content {
    display: block;
  }
  .card-1__mobile a.card > article > figure {
    margin-bottom: 10px;
  }
  .card-1__mobile a.card > article > .a-content {
    height: fit-content;
    position: relative;
  }
  .card-1__mobile a.card > article > .a-content > h2 {
    margin-top: 15px;
    font-size: 17px;
    font-weight: 500;
    max-height: 50px;
  }
  .card-1__mobile a.card > article > .a-content > .a-verdict {
    display: none;
  }
  .card-1__mobile a.card > article > .a-content > p.a-excerpt {
    display: none;
  }
  .card-1__mobile a.card.card-noimage {
    padding: 50px 20px 0;
  }
}

a > p.a-section {
  padding: 3px 15px 1px;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  font-family: "Raleway", helvetica, arial, sans-serif;
  background-color: #000000;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.27px;
  color: #ffffff;
  text-transform: uppercase;
  z-index: 1;
}

a.card {
  display: block;
}

a.card:hover {
  text-decoration: none;
}

a.card article {
  height: 100%;
}

a.card article > figure {
  position: relative;
  _: -ms-lang(x);
  _-overflow: hidden !important;
  _: -ms-lang(x), img;
  _-height: auto !important;
  _-width: auto !important;
  _-min-width: 100% !important;
  _-max-width: 100% !important;
  _-min-height: 100% !important;
  _-max-height: 100% !important;
}

a.card article > figure.video:before {
  position: absolute;
  content: '';
  width: 42px;
  height: 42px;
  background-image: url(../icons/play-icon.svg);
  background-repeat: no-repeat;
  background-size: cover;
  top: 15px;
  right: 15px;
  z-index: 100;
}

a.card article > figure :root img {
  height: auto;
  width: auto;
  max-width: none;
}

a.card article > figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

a.card article > .a-content {
  width: 100%;
}

a.card article > .a-content > p {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

a.card article > .a-content > p.a-excerpt {
  font-size: 13px;
  line-height: 1.31;
  color: #6D7278;
}

a.card article > .a-content .a-subject {
  font-size: 11px;
  color: #0A1726;
  text-transform: uppercase;
  font-weight: 500;
  height: max-content;
  position: relative;
  display: block;
}

a.card article > .a-content .a-subject.a-subject--factcheck {
  color: #ff9c2a;
}

a.card article > .a-content > a.verdict {
  display: none;
}

a.card article > .a-content h2 {
  font-family: "Poppins", helvetica, arial, sans-serif;
  font-size: 48px;
  font-weight: 300;
  margin: 0;
  overflow: hidden;
  padding-bottom: 1px;
  position: relative;
  display: block;
}

a.card article > .a-content > time {
  display: none;
}

a.card article > .a-content .a-arrow {
  display: none;
}

a.card.card-noimage {
  background-color: #EFEFEF;
}

:first-child > a > p.a-section {
  display: block;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers: 
 * http: //snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

/* reset from content builder */
.row > * {
  min-height: 1;
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  div[class*='col-sm'].split {
    width: 50%;
    float: left;
  }
  div[class*='col-sm'].clearfixed {
    clear: both;
  }
}

div[class*='col-sm'].large-split {
  width: 50%;
  float: left;
}

div[class*='col-sm'].clearfixed {
  clear: both;
}

@media screen and (min-width: 768px) {
  .no-padding {
    padding: 0;
  }
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.relative {
  position: relative;
}

.u-no-margin-bottom {
  margin-bottom: 0;
}

.u-no-margin-top {
  margin-top: 0;
}

.no-padding {
  padding: 0;
}

.u-padding-5 {
  padding: 5px;
}

.u-padding-10 {
  padding: 10px;
}

.u-margin-top-100 {
  margin-top: 100px;
}

.u-margin-top-90 {
  margin-top: 90px;
}

.u-margin-top-80 {
  margin-top: 80px;
}

.u-margin-top-70 {
  margin-top: 70px;
}

.u-margin-top-60 {
  margin-top: 60px;
}

.u-margin-top-50 {
  margin-top: 50px;
}

.u-margin-top-40 {
  margin-top: 40px;
}

.u-margin-top-30 {
  margin-top: 30px;
}

.u-margin-top-25 {
  margin-top: 25px;
}

.u-margin-top-20 {
  margin-top: 20px;
}

.u-margin-top-15 {
  margin-top: 15px;
}

.u-margin-top-10 {
  margin-top: 10px;
}

.u-margin-bottom-135 {
  margin-bottom: 135px;
}

.u-margin-bottom-100 {
  margin-bottom: 100px;
}

.u-margin-bottom-80 {
  margin-bottom: 80px;
}

.u-margin-bottom-60 {
  margin-bottom: 60px;
}

.u-margin-bottom-50 {
  margin-bottom: 50px;
}

.u-margin-bottom-40 {
  margin-bottom: 40px;
}

.u-margin-bottom-30 {
  margin-bottom: 30px;
}

.u-margin-bottom-25 {
  margin-bottom: 25px;
}

.u-margin-bottom-20 {
  margin-bottom: 20px;
}

.u-margin-bottom-15 {
  margin-bottom: 15px;
}

.u-margin-bottom-10 {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .u-mobile-float-left {
    float: left;
  }
  .u-mobile-float-right {
    float: right;
  }
  .u-mobile-clear-both {
    clear: both;
  }
  .u-mobile-no-margin-top {
    margin-top: 0 !important;
  }
  .u-mobile-no-margin-bottom {
    margin-bottom: 0 !important;
  }
  .u-mobile-margin-bottom-80 {
    margin-bottom: 80px;
  }
  .u-mobile-margin-bottom-60 {
    margin-bottom: 60px;
  }
  .u-mobile-margin-bottom-50 {
    margin-bottom: 50px;
  }
  .u-mobile-margin-bottom-40 {
    margin-bottom: 40px;
  }
  .u-mobile-margin-bottom-30 {
    margin-bottom: 30px;
  }
  .u-mobile-margin-bottom-25 {
    margin-bottom: 25px;
  }
  .u-mobile-margin-bottom-20 {
    margin-bottom: 20px;
  }
  .u-mobile-margin-bottom-15 {
    margin-bottom: 15px;
  }
  .u-mobile-margin-bottom-10 {
    margin-bottom: 10px;
  }
  .u-mobile-margin-top-110 {
    margin-top: 110px;
  }
  .u-mobile-margin-top-85 {
    margin-top: 85px;
  }
  .u-mobile-margin-top-60 {
    margin-top: 60px;
  }
  .u-mobile-margin-top-50 {
    margin-top: 50px;
  }
  .u-mobile-margin-top-40 {
    margin-top: 40px;
  }
  .u-mobile-margin-top-30 {
    margin-top: 30px;
  }
  .u-mobile-margin-top-25 {
    margin-top: 25px;
  }
  .u-mobile-margin-top-20 {
    margin-top: 20px;
  }
  .u-mobile-margin-top-15 {
    margin-top: 15px;
  }
  .u-mobile-margin-top-10 {
    margin-top: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .u-tablet-float-left {
    float: left;
  }
  .u-tablet-float-right {
    float: right;
  }
  .u-tablet-clear-both {
    clear: both;
  }
  .u-tablet-no-margin-top {
    margin-top: 0 !important;
  }
  .u-tablet-no-margin-bottom {
    margin-bottom: 0 !important;
  }
  .u-tablet-margin-bottom-60 {
    margin-bottom: 60px;
  }
  .u-tablet-margin-bottom-50 {
    margin-bottom: 50px;
  }
  .u-tablet-margin-bottom-40 {
    margin-bottom: 40px;
  }
  .u-tablet-margin-bottom-30 {
    margin-bottom: 30px;
  }
  .u-tablet-margin-bottom-25 {
    margin-bottom: 25px;
  }
  .u-tablet-margin-bottom-20 {
    margin-bottom: 20px;
  }
  .u-tablet-margin-bottom-15 {
    margin-bottom: 15px;
  }
  .u-tablet-margin-bottom-10 {
    margin-bottom: 10px;
  }
  .u-tablet-margin-top-100 {
    margin-top: 100px;
  }
  .u-tablet-margin-top-60 {
    margin-top: 60px;
  }
  .u-tablet-margin-top-50 {
    margin-top: 50px;
  }
  .u-tablet-margin-top-40 {
    margin-top: 40px;
  }
  .u-tablet-margin-top-30 {
    margin-top: 30px;
  }
  .u-tablet-margin-top-25 {
    margin-top: 25px;
  }
  .u-tablet-margin-top-20 {
    margin-top: 20px;
  }
  .u-tablet-margin-top-15 {
    margin-top: 15px;
  }
  .u-tablet-margin-top-10 {
    margin-top: 10px;
  }
}

@media screen and (min-width: 992px) {
  .u-desktop-float-left {
    float: left;
  }
  .u-desktop-float-right {
    float: right;
  }
  .u-desktop-clear-both {
    clear: both;
  }
  .u-desktop-no-margin-top {
    margin-top: 0 !important;
  }
  .u-desktop-no-margin-bottom {
    margin-bottom: 0 !important;
  }
  .u-desktop-margin-bottom-120 {
    margin-bottom: 120px;
  }
  .u-desktop-margin-bottom-100 {
    margin-bottom: 100px;
  }
  .u-desktop-margin-bottom-90 {
    margin-bottom: 90px;
  }
  .u-desktop-margin-bottom-80 {
    margin-bottom: 80px;
  }
  .u-desktop-margin-bottom-70 {
    margin-bottom: 70px;
  }
  .u-desktop-margin-bottom-60 {
    margin-bottom: 60px;
  }
  .u-desktop-margin-bottom-50 {
    margin-bottom: 50px;
  }
  .u-desktop-margin-bottom-40 {
    margin-bottom: 40px;
  }
  .u-desktop-margin-bottom-30 {
    margin-bottom: 30px;
  }
  .u-desktop-margin-bottom-25 {
    margin-bottom: 25px;
  }
  .u-desktop-margin-bottom-20 {
    margin-bottom: 20px;
  }
  .u-desktop-margin-bottom-15 {
    margin-bottom: 15px;
  }
  .u-desktop-margin-bottom-10 {
    margin-bottom: 10px;
  }
  .u-desktop-margin-top-100 {
    margin-top: 100px;
  }
  .u-desktop-margin-top-90 {
    margin-top: 90px;
  }
  .u-desktop-margin-top-80 {
    margin-top: 80px;
  }
  .u-desktop-margin-top-60 {
    margin-top: 60px;
  }
  .u-desktop-margin-top-50 {
    margin-top: 50px;
  }
  .u-desktop-margin-top-45 {
    margin-top: 45px;
  }
  .u-desktop-margin-top-40 {
    margin-top: 40px;
  }
  .u-desktop-margin-top-30 {
    margin-top: 30px;
  }
  .u-desktop-margin-top-25 {
    margin-top: 25px;
  }
  .u-desktop-margin-top-20 {
    margin-top: 20px;
  }
  .u-desktop-margin-top-15 {
    margin-top: 15px;
  }
  .u-desktop-margin-top-10 {
    margin-top: 10px;
  }
}

.u-border-top {
  border-top: 1px solid #6D7278;
}

.u-border-bottom {
  border-bottom: 1px solid #6D7278;
}

.u-border-left {
  border-left: 1px solid #6D7278;
}

.u-border-right {
  border-right: 1px solid #6D7278;
}

@media screen and (max-width: 767px) {
  .u-mobile-border-top {
    border-top: 1px solid #6D7278;
  }
  .u-mobile-border-bottom {
    border-bottom: 1px solid #6D7278;
  }
  .u-mobile-border-left {
    border-left: 1px solid #6D7278;
  }
  .u-mobile-border-right {
    border-right: 1px solid #6D7278;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .u-tablet-border-top {
    border-top: 1px solid #6D7278;
  }
  .u-tablet-border-bottom {
    border-bottom: 1px solid #6D7278;
  }
  .u-tablet-border-left {
    border-left: 1px solid #6D7278;
  }
  .u-tablet-border-right {
    border-right: 1px solid #6D7278;
  }
}

@media screen and (min-width: 992px) {
  .u-desktop-border-top {
    border-top: 1px solid #6D7278;
  }
  .u-desktop-border-bottom {
    border-bottom: 1px solid #6D7278;
  }
  .u-desktop-border-left {
    border-left: 1px solid #6D7278;
  }
  .u-desktop-border-right {
    border-right: 1px solid #6D7278;
  }
}

.u-border-white {
  border-color: #ffffff;
}

@media screen and (max-width: 767px) {
  .five-cols .col-md-1,
  .five-cols .col-sm-1,
  .five-cols .col-lg-1 {
    width: 100%;
    *width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .five-cols .col-md-1,
  .five-cols .col-sm-1,
  .five-cols .col-lg-1 {
    width: 20%;
    *width: 20%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .two-cols .col-md-1, .two-cols .col-sm-1, .two-cols .col-lg-1 {
    width: 50%;
  }
}

@media screen and (min-width: 992px) {
  .two-cols .col-md-2,
  .two-cols .col-sm-2,
  .two-cols .col-lg-2 {
    width: 100%;
    *width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .sm-two-cols .col-md-1, .sm-two-cols .col-sm-1, .sm-two-cols .col-lg-1 {
    width: 50%;
  }
}

@media screen and (min-width: 768px) {
  .three-cols .col-md-1,
  .three-cols .col-sm-1,
  .three-cols .col-lg-1 {
    width: 33.3333%;
    *width: 33.3333%;
  }
}

@media screen and (min-width: 992px) {
  .three-cols .col-md-2,
  .three-cols .col-sm-2,
  .three-cols .col-lg-2 {
    width: 100%;
    *width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .col-profile-left, .col-profile-right {
    float: left;
  }
  .col-profile-left {
    width: 25%;
    padding-right: 30px;
  }
  .col-profile-right {
    width: 75%;
    padding-left: 30px;
  }
}

.gutter-60.row {
  margin-right: -30px;
  margin-left: -30px;
}

.gutter-60 > [class^='col-'], .gutter-60 > [class^=' col-'] {
  padding-right: 30px;
  padding-left: 30px;
}

.gutter-20.row {
  margin-right: -10px;
  margin-left: -10px;
}

.gutter-20 > [class^='col-'], .gutter-20 > [class^=' col-'] {
  padding-right: 10px;
  padding-left: 10px;
}

.gutter-10.row {
  margin-right: -5px;
  margin-left: -5px;
}

.gutter-10 > [class^='col-'], .gutter-10 > [class^=' col-'] {
  padding-right: 5px;
  padding-left: 5px;
}

.gutter-0.row {
  margin-right: 0;
  margin-left: 0;
}

.gutter-0 > [class^='col-'], .gutter-0 > [class^=' col-'] {
  padding-right: 0;
  padding-left: 0;
}

@-ms-viewport {
  width: device-width;
}

.visible-xs {
  display: none !important;
}

.visible-sm {
  display: none !important;
}

.visible-md {
  display: none !important;
}

.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

.u-noscroll {
  overflow-x: hidden;
  overflow-y: hidden;
}
