.c-media-panel{
  position: relative;
  &:nth-child(2){
    border-top: 15px $grey-4 solid;
    border-left: 15px $grey-4 solid;
    &:after{
      content:'';
      position: absolute;
      left: -25px;
      bottom: -10px;
      width: 22px;
      height: 22px;
      transform: rotate(45deg);
      background-color: $white;
      // @media #{$mobile}{
      //   left: -25px;
      // } 
    }
    >.container{
      padding-left: 15px;
    }
  }

  &__head{
    font-size: rem(35);
    font-weight: $bold;
    line-height: 1.2;
    color: $grey-3;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    @media #{$mobile}{
      height: min-content;
    }
    @media #{$tablet-up}{
      writing-mode: unset;

    }
  }

  &__figure{
    width: calc(100% + 30px);
    margin-right: -30px;
  }

  &__image{
    width: 100%;
  }

  &__imagetitle{
    font-size: rem(20);
    font-weight: $sbold;
    color: $orange;
    @media #{$desktop}{
  
    }
  }

  &__text{
    >p{
      font-family: $sans-2;
      font-size: rem(14);
      font-weight: $medium;
      line-height: 1.71;
      @media #{$desktop}{
        font-size: rem(16);
      }
    }
  }

  &__icon{
    margin-top: 10px;
  }

  &__blurbtitle{
    font-size: rem(14);
    font-weight: $bold;
    color: $orange;
    margin-bottom: 0;
  }

  &__blurb{
    font-family: $sans-2;
    font-size: rem(14);
    font-weight: $medium;
    line-height: 1.67;
    color: $grey-1;
  }
}