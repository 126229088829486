.c-index-partners{
  position: relative;
  padding: 80px 10vw 60px; 
  background-color: $grey-4;
  &__headbar{
    background-color: $white; 
    position: relative;
    &:after{
      // content: '\f0d8';
      content: '';
      position: absolute;
      //font-family: FontAwesome;
      width: 50px;
      height: 50px;
      background-color: $white;
      right: calc(50% - 35px);
      bottom: -23px;
      font-size: 2.1875rem;
      line-height: 2.625rem;
      line-height: 1;
      transform: rotate(45deg);
      z-index: 1;
    }
  }

  &__top{
    font-size: rem(12);
    font-weight: $bold;
    line-height: 1.67;
    color: $white;
    text-align: center;
    text-transform: uppercase;
  }

  &__head{
    font-size: rem(24);
    font-weight: $bold;
    line-height: 1.42;
    text-align: center;
    color: $orange;
    margin-bottom: 0;
    @media #{$desktop}{
      
      font-size: rem(31);
    }
  }

  &__logo{
    max-width: 146px;
    max-height: 41px;
    @media #{$desktop}{
      max-width: 180px;
      max-height: 50px;
    }
    
    &--vsg{
      max-height: 38px;
      @media #{$desktop}{
        max-height: 46px;
      }
    }
    &--ausp{
      max-height: 35px;
      @media #{$desktop}{
        max-height: 41px;
      }
    }
    &--wow{
      max-height: 49px;
      @media #{$desktop}{
        max-height: 56px;
      }
    }

    &--wbc{
      max-height: 26px;
      @media #{$desktop}{
        max-height: 30px;
        margin-top: 15px;
      }
      
    }
    &--da{
      max-height: 50px;
      @media #{$desktop}{
        max-height: 57px;
      }
    }
    &--nine{
      max-height: 28px;
      @media #{$desktop}{
        max-height: 33px;
      }
    }
    &--ato{
      max-height: 65px;
      @media #{$desktop}{
        max-height: 77px;
      }
    }
    &--cos{
      max-height: 28px;
      @media #{$desktop}{
        max-height: 32px;
        margin-top: 15px;
      }
    }
    &--mbv{
      max-height: 44px;
      @media #{$desktop}{
        max-height: 50px;
      }
    }
    &--unsw{
      max-height: 44px;
      @media #{$desktop}{
        max-height: 50px;
      }
    }
    
    >img{
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}