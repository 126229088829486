.c-index-quotes{
  padding: 50px 0 0;
  background-color: $navy;
  @media #{$desktop}{
    padding: 70px 0 0;
  }

  &__container{
    position: relative;
  }

  &__overlay{
    width: 194px;
    height: 130px;
    position: absolute;
    bottom: 0;
    right: 0;
    @media #{$desktop}{
      width: 310px;
      height: 203px;     
    }
    >svg{
      width: 100%;
      height: 100%;
    }
  }

  &__quotetext{
    padding-top: 45px;
    >p{
      font-size: rem(22);
      font-weight: $bold;
      line-height: 1.55;
      color: $white;
      @media #{$desktop}{
        font-size: rem(30);  
      }
    }
  }

  &__author{
    font-size: rem(12);
    line-height: 1.5;
    color: $white;
    @media #{$desktop}{
      font-size: rem(16); 
    }
  }

  
  &__position{
    font-size: rem(12);
    font-weight: $bold;
    line-height: 1.5;
    color: $white;
    @media #{$desktop}{
      font-size: rem(16);      
    }
  }

  &__carousel{
    .owl-dots{
      position: absolute;
      left: 0;
      top: 0;
        
      @media #{$mobile}{
        
      }
      @media #{$desktop}{
        
        
      }
    }
    
    .owl-dot{
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: $white !important;
      margin-right: 3px;
      @media #{$tablet}{
        margin-right: 6px;
    
      }
      &.active{
        background-color: $orange !important;
      }
    }
    
  }
}