.c-archive {
  &__head{

    
    >h1{
      font-size: rem(29);
      font-weight: $sbold;
      //text-transform: lowercase;
      order: 1;
      margin-bottom: 0;
      width: max-content;
      padding-right: 10px;
      background-color:$white;
      z-index: 11;
      position: relative;
    } 
    // &:after{
    //   content: '';
    //   display: block;
    //   height: 1px;
    //   background-color:$grey-1;
    //   opacity: .5;
    //   z-index: 10;
    //   order: 2;
    //   flex-grow: 1;
    // }
    // &--noafter{
    //   &:after{
    //     display: none;
    //   }
    // }
  }
  
}
