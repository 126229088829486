.navigation-logo {
  z-index: 500;
  float: left;
  margin-top: 25px;
  @media #{$up-to-desktop}{
    margin-top: 20px;
    margin-left: 15px;
  }
}




.navigation-logo__link {
  display: block;
  padding: 0;
}
.navigation-logo__img{
  height: 49px;
  @media #{$mobile} {
    height: 40px;
  }
  @media #{$tablet} {
    height: 45px;
  }
  &--dark{
    svg>g{
      fill: $white;
    }
  }
}

