/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: color(font-link);
  text-decoration: none;
  font-weight: $sbold;
  &:hover {
    text-decoration: none;
  }
}
