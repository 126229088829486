.c-products{
  padding: 50px 0;
  background-color: $navy;

  &__head{
    font-size: rem(28);
    font-weight: $bold;
    line-height: 1.25;
    color: $orange;
    text-align: center;
    @media #{$desktop}{
      font-size: rem(40);
      margin-bottom: 10px;
    }
  }

  &__subhead{
    font-size: rem(18);
    font-weight: $bold;
    line-height: 1.38;
    
    color: $grey-4;
    text-align: center;
    @media #{$desktop}{
      font-size: rem(26);
    }
  }

  &-card{

    @media #{$mobile}{
      padding: 10px 0;
      margin-bottom: 25px;
      
    }

    &__icon{
        height: 35px;
        @media #{$up-to-desktop}{
          >svg{
            margin: 0 auto;
            display: block;
          }
        }
    }

    &__head{
      font-size: rem(14);
      font-weight: $sbold;
      color: $orange;
      @media #{$up-to-desktop}{
        text-align: center;
      }
      @media #{$tablet-up}{
        font-size: rem(20);
        letter-spacing: -0.1px;
      }
    }

    &__text{
      display: none;
      @media #{$tablet-up}{
        display: block;
        >p{
          @media #{$up-to-desktop}{
            text-align: center;
            padding: 0 15px;
          }
          font-size: rem(14);
          color: $white;
          font-family: $sans-2;
          font-weight: $medium;
          line-height: 1.71;
          
        }
      }

    }
  }
}