// .site-header,
// .site-nav__menu-hamburger,
// .show-on-tablet > ul,
// .site-nav,.site-menu,
// .site-menu__container,
// .site-menu__item.now-active > a:after{
//   @extend %transition;
// }


.site-nav{
  margin: 0;
  list-style: none;
  //background-color: #fff;
  height: 102px;
  padding: 30px 0 0;
  white-space: nowrap;
  

  @media #{$up-to-desktop}{
    height: auto;
    border-bottom: 0;
    padding: 0;   
  }
  @media #{$tablet}{
    float: right;
    margin-right: 35px;
    margin-top: 30px;
  }

  @media #{$mobile}{
    //right: 35px;
    position: relative;
  }

  ul.mobile {
    @media  #{$up-to-desktop}{
      display: none;
    }
  }
  &__menu-hamburger{
    display: none;
    @media #{$mobile}{
      position: absolute;
      right: 25px;
      top: 25px;
      //width: 50px;
      height: 40px;
    }
  
    @media #{$up-to-desktop}{
      display: block;
    }
  
    z-index: 999;
    
  }
  &__menu-hamburger{
    &--closed{
      @media #{$mobile}{
        >svg{
          height: 25px;
          width: 25px;
        }
      }
      display: block;
    }
    &--open{
      display: none;
    }
    &__cross{
      padding-right: 2px;
      padding-top: 2px;
      >svg {
        position: relative;
        width: 20px;
        height: 20px;
        >g{
          // stroke: $orange;
        }
      }
    
    }
  }
}

.site-menu{
  float: right;
  position: relative;
  @media #{$up-to-desktop}{
    display: none;

    &.site-menu--open{
      display: block;
      position: absolute;
      width: 100%;
      background-color: $white;
      top: 75px;
      @media #{$tablet}{
        background-size: 40%;
        right: 0;
        border-top: none;
        border-right: none;
        border-left: none;
        top: 80px;
      }
      @media #{$mobile}{
        height: min-content;
        overflow: scroll;
        // max-height: 400px;
        // &:after{
        //   content: '';
        //   height: 40px;
        //   width: 100%;
        //   background-color: $grey-4;
        //   display: block;
        //   position: fixed;
        //   opacity: 0.9;
        //   top: 517px;
        // }
      }
    }
  }

  @media #{$tablet} {
    top: 95px;
  }

  @media #{$mobile} {
    top: 89px;
  }
  &__item{
    display: inline-block;
    margin: 0;
    position: relative;
    height: 100%;

    @media screen and (min-width: $mmd-break) {
      margin-left: 12px;
    }

    @media #{$tablet} {
      display: block;
      min-height: 66px;
      width: auto;
      font-size: rem(16);
      font-weight: $medium;
      width: 100%;
      float: none;
      display: block;
      // border-bottom: 1px solid $grey-3;
      
    }
    @media #{$mobile} {
      display: block;
      width: auto;
      font-size: rem(16);
      font-weight: $medium;
      width: 100%;
      float: none;
      display: block;
      // border-bottom: 1px solid $grey-3;
    }

    

    
    
  }

  &__link{
    font-family: $sans-2;
    font-size: rem(18);
    height: 100%;
    display: block;
    // text-transform: lowercase;
    font-weight: $sbold;
    color: $black;
    position: relative;
    padding: 0 20px 0 0;
    letter-spacing: 0.45px;
    
    @media  #{$tablet}{
      font-size: rem(18);
      padding: 20px 25px;
      font-weight: $sbold;
      
      margin-right: 100px;
      color: $black;
      min-height: 66px;
    }

    @media  #{$mobile}{
      font-size: rem(16);
      padding: 10px 25px;
      font-weight: $sbold;
      margin-right: 50px;
      color: $black;
    }

    
    &:hover{
      text-decoration: none;
    }
    &--login{

      &:after{
        content: '';
        width: calc(100% - 20px);
        height: 3px;
        background-color: $orange;
        position: absolute;
        display: block;
        @media #{$tablet}{
          width: 55px;
        }
        @media #{$mobile}{
          width: 44px;
        }
      }
    }
    &--button{
      height: 40px;
      padding: 11px 29px;
      width: 100%;
      &:hover{
        padding: 10px 28px;
      }
      color: $white;
      font-size: rem(16);
      // padding: 0;
      line-height: 1;
      font-weight: $bold;
      @media #{$tablet}{
        min-height: unset;
        width: min-content;
        margin: 0;
        position: absolute;
        right: 120px;
        top: 23px;
      }
    }
  }

  &__item--current{
    color: $grey-3;
  }

  &__search{
    height: 19px;
    margin-bottom: -3px;
    >svg{
      height: 100%;
    }
  }
  &__container{
    margin-bottom: 0;
  }
  &__overlay{
    background-color: $black;
    opacity: 0.55;
    z-index: -1;
    height: 1500px;
    width: 100%;
    position: absolute;
    top: 117px;
    @media #{$desktop}{
      display: none;
    }
  }
  &__quote{
    position: absolute;
    bottom: 30px;
    padding: 0 25px;
    width: 70%;
    @media #{$mobile} {
      position: fixed;
    }
    
    &__head{
      color: $orange;
      font-size: rem(15);
      font-weight: $bold;
    }
    &__text{
      font-size: rem(25);
      font-weight: $bold;
      color: $white;
      word-wrap: break-word;
    }
    &__author{
      font-size: 14px;
      font-weight: $medium;
      text-transform: uppercase;
      color: $white;
    }
  }
}
.site-sub-menu{
  z-index: 900;
  display: none;
  position: absolute;
  list-style: none;
  margin: 0;
  min-width: 278px;
  width: 278px;
  left: 0;
  top: 30.5px;
  margin-top: 0;
  box-shadow: 0 10px 8px 2px rgba(109, 114, 120, 0.2);
  padding: 10px 0;
  &:before{
    content: '';
    background: $transparent;
    height: 11px;
    width: 100%;
    position: absolute;
    top: -3px;
  }
  
  @media #{$up-to-desktop}{   
    height: auto;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    bottom: 0;
    position: static;
    box-shadow: none;
  }

  @media #{$up-to-desktop}{
    left: auto;
    width: 100%;
    margin: 0;
    top: 0;
  }
  &__item {
    height: 100%;
    text-align: left;
    //border-bottom: 1px solid $grey-1;
    margin-bottom: 0;
    margin-left: 0;
    color: $black;
    background: $white;
    padding: 0 20px;
    
    
    @media #{$tablet}{
      margin: 0;
      position: relative;
      display: block;
      height: auto;
      padding-left: 30px;
      background: $white;
      min-height: 50px;
      padding: 0 0 0 30px;
      width: auto;
      &:first-child{
        padding-top: 10px;
      }
      &:last-child{
        padding-bottom: 10px;
      }
    }
    @media #{$mobile}{
      margin: 0;
      position: relative;
      display: block;
      height: auto;
      padding-left: 30px;
      background: $white;
      padding: 0;
      width: auto;
    }

    // &:hover {
    //   background: $orange;
    //   > a {
    //     color: $white;
    //   }
    // }
  }

  &__link {
    font-size: rem(13);
    padding: 10px;
    display: block;
    font-weight: $medium;
    color:  $black;
    // text-transform: lowercase;
    letter-spacing: 0.4px;
    text-align: left;
    max-width: 260px;
    overflow: hidden;
    // border-bottom: 1px solid $grey-3;
    
    @media #{$tablet}{
      font-size: rem(14);
      height: 100%;
      display: block;
      font-weight: $light;
      position: relative;
      padding: 17px 15px 17px 25px;
      text-align: left;
      max-width: unset;
      letter-spacing: 0.48px;  
    }

    @media #{$mobile}{
      font-size: rem(14);
      height: 100%;
      display: block;
      font-weight: $light;
      position: relative;
      padding: 10px 15px 10px 25px;
      text-align: left;
      max-width: unset;
      letter-spacing: 0.48px;  
      height: 39.5px;
    }

    &:hover {
      text-decoration: none;
      color: $orange;
    }
  }
  >li:last-child>a{
    border-bottom: none;
  }
}

.secondary-menu{
  display: none;
}

.site-menu--selected {
  &:before{
    content: '';
    position: absolute;
    height: 48px;
    width: 14px;
    top: -52px;
    left: 0;
    background-color: $orange;
  }
  
  @media #{$up-to-desktop}{
    &:before{
      top: unset;
      left: 0;
    }
  }
  &-hamburger{
    >.site-nav__menu-hamburger{
      &--closed{
        display: none;

      }
      &--open{
        display: block;
      }
    }
  }
  >.site-menu__link{
    // color: $orange;
    // @media #{$up-to-desktop}{
    //   color: $white;
    //   >strong{
    //     color: $white;
    //   }
    // }
    // &.site-menu__link--factcheck{
    //   color: $orange;
    //   >strong{
    //     color: $orange;
    //   }
    // }
    
  }

  > .site-sub-menu{
    display: block;

    @media #{$up-to-desktop}{
      position: inherit;
      
      // > .site-sub-menu__link {
      //   background-color: $orange;

      // }
    }
  }
  &.site-menu__item--nobg{
    background-color: $transparent;
  }
}

.site-sub-menu__expand {
  float: right;
  width: 50px;
  height: 66px;
  padding: 25px 75px 25px 15px;
  transition: transform ease-in;
  >svg>path{
    fill: $grey-3;
  }
  @media #{$mobile}{
    width: 39.5px;
    height: 39.5px;
    padding: 10px 15px;
    position: absolute;
    right: 17px;
    >svg{
      height: 20px;
    }
  }
}

.site-sub-menu__expanded {
  >svg{

    transform: rotate(90deg);
    >path{
      fill: $black;
    }
  }
}

.site-sub-menu__noexpand {
  float: right;
  width: 50px;
  height: 50px;
}

