.showOnScroll {
  top: 0 !important;
}

.site-header {
  height: 130px;
  position: relative;
  padding: 0;
  background-color: #fff;
  z-index: 140;
  width: 100%;
  @media #{$desktop} {
    padding: 15px 50px;
  }
  
  // @include transition(100000s);
  @media #{$tablet} {
    height: 80px;
    position: fixed;
    top: 0;
    // border-bottom: 1px $grey-3 solid;
  }

  @media #{$mobile} {
    height: 79px;
    //padding-bottom: 65px;
    position: fixed;
    top: 0;
    //border-bottom: 1px $grey-3 solid;
  }
  &__container{
    @media #{$up-to-desktop}{
      padding: 0;
    }
    @media #{$tablet}{
      max-width: 100%;
      padding: 0 25px;
    }
  }
  &--dark{
    @media #{$up-to-desktop} {
      background: $black;
      background-image: url(../assets/images/dots.png);
      background-repeat: repeat-y;
      background-position: right;
      background-size: 40%;
    }
    @media #{$mobile}{
      background-size: 60%;
    }
  }
}
