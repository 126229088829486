.o-hr {
  &--grey{
    &-15{
      border-top: 1px $grey-1 solid;
      margin: 15px 0;
      width: 100%;
      
    }
    &-25{
      border-top: 1px $grey-1 solid;
      margin: 25px 0;
      width: 100%;
     
    }

    &-50{
      border-top: 1px $grey-1 solid;
      margin: 50px 0;
      width: 100%;
     
    }

  
    &-m-full{
      display: none;
      @media #{$mobile}{
        display: block;
        border-top: 1px $grey-1 solid;
        margin: 15px 0;
        padding: 0;
        width: 100%;
      }
    }

    &-half{
      border-top: 1px $grey-1 solid;
      margin: 50px 0;
      width: 30%;
      
    }

  }

  &--white{
    border-color: $white;
  }
  
  
  &--hidden{
    border: none;
    width: 100%;
    
  }
  
  
}