@mixin card-1-base() {
  position: relative;
  a.card {  
   
    > article {
      position: relative;
      figure{
        
        margin-bottom: 10px;
        
      }
      > .a-content{
        padding: 0;
        position: relative;

        > div {
          display: none;
        }
        
        > h2{
          text-align: left;
          font-family: $sans-2;
          color: $black;
        }
        > p.a-excerpt {
          display: none;
        }
      }
      // > time {
      //   display: none;
      // }
    }   
  }
}


.card-1{
  &__desktop {
    @media screen and (min-width: $mmd-break){
      @include card-1-base();


      a.card {
        @include card-image-top(100%, 223px);
        //height: 360px;
        margin-bottom: 40px;
        > article {
          > .a-content{
            //height: 115px;
            position: relative;


            > h2{
              margin-top: 15px;
              font-size: rem(18);
              max-height: 84px;
              //height: 84px;
              margin-bottom: 5px;
              font-weight: $medium;
            }
            >.a-excerpt{
              font-size: rem(13);
              max-height: 50px;
              overflow: hidden;
              margin-top: 15px;
            }
    
          }
        }
        &.card-noimage{
          padding: 75px 20px 0;
          p.a-excerpt {
            display: block;
          }
        }
      }
    }
  }
  &__tablet {
    @media screen and (min-width: $sm-break) and (max-width: $mmd-break-down) {
      @include card-1-base();


      a.card {
        @include card-image-top(100%, 134px);
        //height: 280px;
        margin-bottom: 40px;
        > article {
          > .a-content{
            height: 65px;
            position: relative;


            > h2{
              margin-top: 10px;
              font-size: rem(16);
              max-height: 84px;
              margin-bottom: 10px;
              font-weight: $medium;
              line-height: normal;
            }
            >.a-excerpt{
              font-size: rem(15);
              max-height: 20px;
              overflow: hidden;
            }
          }
        }
        &.card-noimage{
          padding: 75px 20px 0;
          p.a-excerpt {
            display: block;
          }
        }
      }
    }
  }
  &__mobile {
    @media #{$mobile} {
      @include card-1-base();

      a.card {
        @include card-image-top(100%, 165px);
        margin-bottom: 30px;
        > article {
          >figure{
            margin-bottom: 10px;
          }
          > .a-content{
            height: fit-content;
            position: relative;
            //padding-bottom: 40px;
            > h2{
              margin-top: 15px;
              font-size: rem(17);
              font-weight: $medium;
              max-height: 50px;
            }
            >.a-verdict{
              display: none;

            }
            >p.a-excerpt{
              display: none;
            }
          }
        }
        &.card-noimage{
          padding: 50px 20px 0;
        }
      }
    }
  }
}
