.c-about {
  &__head{
    font-size: rem(40);
    font-weight: $bold;
    line-height: 1.05;
    color: $orange;


    @media #{$tablet-up}{
      writing-mode: vertical-rl;
      text-orientation: mixed;
    }
  }

  &__content{
    >p{
      font-size: rem(16);
      line-height: 1.75;
      color: $grey-1;
      margin-bottom: 20px;
      @media #{$desktop}{
             
      }
    }
  }

  &__company{
    font-size: rem(14);
    font-weight: $sbold;
    color: $orange;

  }

  &__website{
    font-size: rem(12);
    font-weight: $medium;
    line-height: 1.67;
    color: $grey-2;

  }

  &__arrow{
    transform: rotate(270deg);
    position: absolute;
    right: 45px;
    height: 50px;
    width: 14px;
    top: -5px;
  }

}
