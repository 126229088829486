.c-article {
  &__figure{
    height: 494px;
    width: 100%;
    margin-bottom: 10px;
    &--factcheck{
      height: 398px;
    }
  
    @media #{$mobile}{
      height: 240px;
      width: calc(100% + 30px);
      margin: 0 -15px;
    }

    

  }
  &__img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__figcaption{
    font-size: 12px;
    font-weight: $light;
    line-height: 1.58;
    color: $grey-1;
  }
  &__subject{
    font-family: $sans-2;
    font-size: rem(10.5);
    font-weight: $sbold;
    line-height: 1.43;
    letter-spacing: 0.44px;
    text-transform: uppercase;
    margin-top: 35px;
    margin-bottom: 15px;
    &--factcheck{
      color: $orange;
      margin-top: 0;
    }
  }
  &__title{
    font-size: rem(37);
    width: 100%;
    padding-bottom: 28px;
    border-bottom: 1px $black solid;
    font-weight: $sbold;
    line-height: 1.22;
    letter-spacing: 0.06px;
    margin-bottom: 10px;
  }
  &__date{
    font-size: rem(13);
    font-weight: $light;
    line-height: 1.62;
    text-align: right;
  }
  &__byline{
    font-size: rem(13);
    font-weight: $light;
    line-height: 1.62;
    text-align: left;
  }
  &__social{
    float: left;
    height: 25px;
    margin-top: 15px;
    &__icon{
      width: 17px;
      float: left;
      margin-right: 10px;
      >svg{
        width: 17px;
      }
    }
    &--factcheck{
      >a>svg>path{
        fill: $orange;
      }
    }
  }
  &__content{
    margin-top: 40px;
    >p{
      font-size: rem(16);
      line-height: 1.5;
    }
    
    &--factcheck{
      h2{
        font-size: rem(20);
        font-weight: $sbold;
        line-height: 0.75;
        color: $orange;
        text-transform: uppercase;
        @media #{$mobile}{
          font-size: rem(17);
        }
        &:nth-of-type(even){
          color: $black;
        }
      }
      img{
        border: 1px solid $grey-4;
      }
      ul>li,>li{
        color: $black;
      }
      @media #{$tablet}{
        >p{
          font-size: rem(18);
          line-height: 29px;
        }
      }
    }
  }
  &__verdict{
    padding: 50px 30px;
    background-color: $grey-4;
    >ul{
      margin: 0;
      >li{
        list-style: none;
        color: $black;
        margin-left: 0;
        font-weight: $medium;
        >strong{
          text-transform: uppercase;
          font-size: rem(17);
        }
      }
    }
  }
  &__others{
    &-head{
      font-size: rem(27);
      font-weight: $sbold;
      line-height: 1.22;
      color: $black;

    }
  }
}
