.c-media{
  

  &__head{
    font-size: rem(40);
    font-weight: $bold;
    line-height: 1.05;
    color: $orange;
    padding-right: 45px;
    @media #{$desktop}{
      
     }
  }

  &__snippet{
    font-size: rem(20);
    font-weight: $sbold;
    color: $grey-1;

    @media #{$desktop}{
      
    }
  }

  &__content{
    >p{
      font-size: rem(16);
      line-height: 1.75;
      color: $grey-1;
      margin-bottom: 20px;
      @media #{$desktop}{
             
      }
    }
  }

  &__blurbtitle{
    font-family: $sans-2;
    font-size: rem(14);
    font-weight: $sbold;
    color: $orange;
    margin-bottom: 0;
  }

  &__blurb{
    font-family: $sans-2;
    font-size: rem(14);
    font-weight: $medium;
    line-height: 1.67;
    color: $grey-1;
  }

  
}