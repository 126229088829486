.c-page {
  &__head{
    >h1{
      font-size: rem(29);
      font-weight: $sbold;
      //text-transform: lowercase;
      order: 1;
      margin-bottom: 0;
      width: max-content;
      padding-right: 10px;
      background-color:$white;
      z-index: 11;
      position: relative;
      >strong{
        color: $orange;
      }
    } 
    // &:after{
    //   content: '';
    //   display: block;
    //   height: 1px;
    //   background-color:$grey-1;
    //   opacity: .5;
    //   z-index: 10;
    //   order: 2;
    //   flex-grow: 1;
    //   margin-top: -5px;
    // }
    // &--noafter{
    //   &:after{
    //     display: none;
    //   }
    // }
  }
  &__subhead{
    margin-top: 10px;
    font-size: 25px;
    font-weight: $regular;
    letter-spacing: 0.38px;
    

  }
  &__content{
    >p{
        font-size: rem(16);
        font-weight: $light;
        line-height: 1.5;
        letter-spacing: 0.77px;
        color: $black;
        margin-bottom: 15px;
        >strong{
          font-weight: $bold;
        }
        @media #{$tablet} {
          font-size: rem(15);
        }
    }
    >.wp-block-image{
      >img{
        max-width:100%;
      }
    }
    h1,h2,h3,h4{
      color: $black;
    }
    >iframe{
      width: 100%;
      margin: 0px;
      border: none;
      display: block;
      height: 442px;
      overflow: hidden;
      @media #{$tablet} {
        height: 700px;
      }
      @media #{$mobile} {
        height: 1000px;
      }
    }
  }
}
