.o-button {
  cursor: pointer;
  border: none;
  height: 60px;
  width: 245px;
  padding: 18px 65px;
  background-color: $orange;
  color: $white;
  font-weight: $sbold;
  font-size: rem(16);
  line-height: 1.75;
  text-align: center;

  @media #{$mobile}{
    height: 38px;
    
    width: 100%;
    padding: 10px 20px;
  }
  &:hover{
    color: $orange;
    background-color: $white;
    border: 1px solid $orange;
    padding: 17px 64px;
    @media #{$mobile}{
      padding: 9px 19px;
    }
  }

  &--nobg{
    background-color: $transparent;
    border: 1px solid $orange;
    color: $orange;
    padding: 17px 84px;
    &:hover{
      border: none;
      background-color: $orange;
      color: $white;
      padding: 18px 85px;
    }
  }
  @media #{$mobile}{
    height: 38px;
    width: max-content;
    padding: 10px 20px;
    padding: 10px 20px;
    line-height: 1;
  }
}
