/* ==========================================================================
   #Content of editable pages and posts
   ========================================================================== */

.e-content {
  display: block;
  p{
    color: $black;
    font-size: rem(15);
    //opacity: 0.8;
    font-weight: $regular;
    line-height: 1.5;
    margin-bottom: 25px;
    @media #{$mobile}{
      font-size: rem(16);
    }
    strong{
      font-weight: $bold;
    }
    &.has-medium-font-size{
      font-size: rem(20);
      color: $black;
    }
  }
  h1,h2,h3,h4{
    color: $black;
  }
  ul,ol{
    margin-left: 15px;
    li{
      color: $grey-1;
      font-size: rem(16);
      opacity: 0.8;
      font-weight: $regular;
      line-height: 1.5;      
      margin-bottom: 15px;
    }
  }
  ul{
    li{
      list-style-type: disc;
    }
  }
  .wp-block-button{
    //width: 100%;
    //float: left;
    margin-right: 10px;
    margin-bottom: 30px;
    a{
      height: 47px;
      padding: 14px 32px;
      background-color: $orange;
      color: $white;
      font-weight: $bold;
      border-radius: unset;
      display: unset;
      font-size: rem(12);
      @media #{$mobile}{
        width: 100%;
        display: block;
        margin-bottom: 15px;
      }
      &:hover{
        color: $orange;
        background-color: $white;
        border: 1px solid $orange;
        padding: 13px 31px;
      }
    }
    @media #{$mobile}{
      width: 100%;
    }
    
  }
  .c-button-block{
    width: 100%;
    display: block;
    .wp-block-button{
      float: left;
      width: min-content;
    }
  }
  a{
    color: $orange;
  }
  .c-embed{
    >iframe{
      max-width: 100%;
    }
  }
  .wp-block-image{
    >img{
      max-width:50%;
      margin-bottom:  2em;
      @media #{$mobile} {
        max-width:100%;
        max-height: 250px;
        object-fit: contain;
      }
    }
    >figcaption{
      float: right;
      width: 45%;
      margin-top: 25px;
      @media #{$mobile} {
        width:100%;
        margin-top: 0;
        font-size: rem(12);
        font-weight: $light;
        line-height: 1.58;
        color: $grey-1;
      }
    }
  }
}