.c-footer-menu{
    
    &__col{
        width: max-content;
        margin-right: 30px;
        @media #{$mobile}{
            width: 100%;
            margin: 0 auto 0 30px;
            &:first-child{
                margin-top: 30px;
            }
        }
        
    }
    &__li{
        list-style: none;
        @media #{$mobile}{
            margin-bottom: 0;
            margin-left: 2px;
        }
        @media #{$tablet}{
            margin-bottom: 0;
            margin-left: 0;
        }
        &--nochild{
            margin-bottom: 18px;
        }
    }
    &__link{
        font-family: $sans-2;
        color: $white;
        margin-bottom: 5px;
        // text-transform: lowercase;
        @media #{$up-to-desktop}{
            font-size: rem(12);
        }
        &:hover{
            color: $orange;
        }
        &--main{
           font-weight: $medium;
        }
        &--child{
            font-weight: $regular;
        }
        &--blue{
            color: $orange;
        }
        &--head{
            font-weight: $bold;
            &:hover{
                color: $grey-1;
            }
        }
        &--login{
            position: relative;
            margin-bottom: 10px;
            &:after{
              content: '';
              width: 100%;
              height: 3px;
              background-color: $orange;
              position: absolute;
              display: block;
              bottom: -5px;
            }
            
        }

        >strong{
        //    text-transform: capitalize;
           font-weight: $sbold;
        }
        
    }

    &__text{
        font-family: $sans-2;
        font-size: rem(12);
        font-weight: $sbold;
        line-height: 1.67;
        color: $white;
    }
    
    &__legal{
        &__item{
            >a,p{
                font-family: $sans-1;
                font-size: rem(13);
                letter-spacing: 0.44px;
                color: $white;
                font-weight: $regular;
                @media #{$tablet}{
                    font-size: rem(11);
                    letter-spacing: 0;
                  }
                @media #{$mobile}{
                    font-size: 11px;
                    font-weight: $light;
                    letter-spacing: 0.38px;
                    color: $white;
                }
            }
            float: left;
            padding-left: 15px;
            padding-right: 10px;
            &:before{
                content: '';
                height: 12px;
                width: 1px;
                background-color: $grey-1;
                display: block;
                position: absolute;
                margin: 7px 0 0 -13px;
            }
            &:last-child{
                >p{
                    margin-top: 3px;
                }
            }
            &:first-child{
                padding-left: 0;
                &:before{
                    display: none;
                }
            }
        }
    }
}