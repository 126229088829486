.c-login-modal{
  background-color: $overlay;
  &__close{
    right: 0;
  }
  &__icon{
    >svg{
      width: 15px;
      height: 15px;
    }
  }
  &__head{
    font-size: rem(20);
    font-weight: $bold;
    line-height: 1.8;
    letter-spacing: 0.62px;
    color: $black;
    margin-bottom: 15px;
    text-align: center;
   
  }

  &__input{
    width: 100%;
    height: 50px;
    border-radius: 9px;
    border: solid 1px $grey-3;
    padding: 16px 21px;
    font-family: $sans-2;
    font-weight: $bold;
    font-size: rem(16);
    line-height: 1.13;
    &::placeholder{
      font-weight: $sbold;
      color: $grey-3;
    }

  }

  &__forgot{
    font-family: $sans-2;
    font-size: rem(12);
    font-weight: $sbold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    color: $grey-1;
    text-decoration: underline;
  }


  &__submit{
    padding: 9px 40px;
    font-size: 16px;
    font-weight: $bold;
    text-align: center;
    color: $white;
    width: max-content;
    display: block;
    margin: 0 auto;
    &:hover{
      padding: 8px 39px;
    }
  }

 
}