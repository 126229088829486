/* ==========================================================================
   #HR
   ========================================================================== */

hr {
  display: block;
  width: 100%;
  border-top: 1px solid $grey-3;
  color: $transparent;
  margin-bottom: 15px;
  &.e-hr{
    &-15{
      margin: 15px 0;
      
    }
    &-25{
      margin: 25px 0;
      
    }
    &-30{
      margin: 30px 0;
      
    }
    &-60{
      margin: 60px 0;
    }


    &-mini{
      width: 50px;
      margin-right: auto;
      
    }
    &-lightgrey{
      border-color: $grey-3;
    }
    &-hidden{
      border-color: $transparent;
    }
    &-thick{
      border-top: 2px solid $grey-3;
    }
  }
}