.c-contactbar{
  padding: 25px 0 35px;
  background-color: $navy;

  @media #{$desktop}{
    padding: 70px 0;
  }

  &__head{
    font-size: rem(20);
    font-weight: $bold;
    line-height: 1.38;
    color: $white;
    @media #{$mobile}{
      text-align: center;
      margin-bottom: 15px;
    }
    
    @media #{$tablet-up}{
      font-size: rem(26);
    }

  }

  &__text{
    font-size: rem(15);
    line-height: 1.11;
    color: $grey-3;
    @media #{$mobile}{
      text-align: center;
      margin-bottom: 20px;
    } 
    @media #{$tablet-up}{
      font-size: rem(18);
    }
  }

  &__button{
    margin: 0 0 0 auto;
    @media #{$mobile}{
      margin: 0 auto;
    }
  }

}

